import React, { ChangeEvent, useEffect, useState } from 'react'
import { Page } from '../../../common/components/pages/page'
import Button, { LoadingButton } from '@atlaskit/button'
import Select from '@atlaskit/select'
import TextField from '@atlaskit/textfield'
import DynamicTable from '@atlaskit/dynamic-table'
import EmptyState from '@atlaskit/empty-state'
import SearchIcon from '@atlaskit/icon/glyph/search'
import { N400 } from '@atlaskit/theme/colors'
import apiFetch from '../../../common/utils/apiFetch'
import {
  PropertyFieldConfiguration,
  PropertyFieldType
} from 'common/types/crm/property-schema'
import { useDebounce } from 'use-debounce'
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup
} from '@atlaskit/dropdown-menu'
import {
  ContentWrapper,
  FlexContainer,
  RightSidePanel
} from '@atlaskit/right-side-panel'
import MoreIcon from '@atlaskit/icon/glyph/more'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import { PropertyEditor } from '../components/property-editor'
import {
  EntityPropertySchema,
  LightEntitySchema
} from 'common/types/odino/entity-schema'
import { capitalizeFirstLetter } from '../../../common/utils/string-utils'
import { LightRelationSchema } from 'common/types/odino/relation-schema'

export const AdminPageProperties = () => {
  const [selectedGraphType, setSelectedGraphType] = useState<string>('entities')
  const [entityTypes, setEntityTypes] = useState<LightEntitySchema[] | LightRelationSchema[]>([])
  const [selectedType, setSelectedType] = useState<LightEntitySchema | LightRelationSchema>()
  const [properties, setProperties] = useState<{[key: string]: {[key: string]: EntityPropertySchema}}>({})
  const [groups, setGroups] = useState<any>([])
  const [filter, setFilter] = useState<string | undefined>()
  const [debouncedFilter] = useDebounce(filter?.toLowerCase(), 500)
  const [filteredProps, setFilteredProps] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedProperty, setSelectedProperty] = useState<EntityPropertySchema>()
  const [isSaving, setIsSaving] = useState(false)
  const [isSelectedPropInvalid, setIsSelectedPropInvalid] = useState<boolean>(false)
  const [isEditMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (selectedGraphType) {
      refresh()
    }
  }, [selectedGraphType])

  useEffect(() => {
    if (selectedType) {
      getProperties()
    }
  }, [selectedType])

  useEffect(() => {
    if (selectedType) {
      let selectedProperties = Object.values<EntityPropertySchema>(properties[selectedType.type] ?? {})
      if (debouncedFilter) {
        selectedProperties = selectedProperties.filter(q => q.name?.toLowerCase().includes(debouncedFilter))
      }
      setFilteredProps(selectedProperties)
    } else {
      // TODO
    }
  }, [properties, debouncedFilter, selectedType])

  const refresh = async (): Promise<void> => {
    setIsLoading(true)
    let allowedEntities: LightEntitySchema[] | LightRelationSchema = []
    const graphResponse = await apiFetch<{entities: {crm: LightEntitySchema[], system: LightEntitySchema[]}}>('graph')
    allowedEntities = allowedEntities
      .concat(graphResponse[selectedGraphType].crm)
      .concat(graphResponse[selectedGraphType].system)
    setEntityTypes(allowedEntities)

    if (allowedEntities.length > 0) {
      console.log(selectedType)
      if (!selectedType) {
        setSelectedType(allowedEntities[0])
      }
    } else {
      setIsLoading(false)
    }
  }

  const getProperties = async (): Promise<void> => {
    setIsLoading(true)
    if (selectedType) {
      const resp = await apiFetch(`graph/schema/${selectedGraphType}/${selectedType.type.toLowerCase()}`)
      const newProperties = { ...properties }
      newProperties[selectedType.type] = resp.properties
      setProperties(newProperties)
      const newGroups = { ...groups }
      newGroups[selectedType.type] = resp.groups
      setGroups(newGroups)
    }
    setIsLoading(false)
  }

  const saveProperty = async (): Promise<void> => {
    setIsSaving(true)
    try {
      if (isEditMode && selectedProperty) {
        await apiFetch(`graph/schema/${selectedGraphType}/${selectedType?.type.toLowerCase()}/${selectedProperty.id}`, {
          method: 'PUT',
          body: JSON.stringify(selectedProperty)
        })
      } else {
        await apiFetch(`graph/schema/${selectedGraphType}/${selectedType?.type.toLowerCase()}`, {
          method: 'POST',
          body: JSON.stringify(selectedProperty)
        })
      }
      await refresh()
      await getProperties()
      setSelectedProperty(undefined)
    } catch (e) {
      // TODO: Show error flag
      console.log(e)
    }
    setIsSaving(false)
    setEditMode(false)
  }

  const deleteProperty = async (propId: string): Promise<void> => {
    setIsLoading(true)
    try {
      await apiFetch(`graph/schema/${selectedGraphType}/${selectedType?.type.toLowerCase()}/${propId}`, {
        method: 'DELETE'
      })
      setSelectedProperty(undefined)
      await refresh()
      await getProperties()
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
    setEditMode(false)
  }

  const closePanel = () => {
    setEditMode(false)
    setSelectedProperty(undefined)
  }

  const barContent = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 200px' }}>
        <Select
          spacing='compact'
          placeholder='Choose the graph type'
          aria-label='Choose the graph type'
          value={{ key: selectedGraphType, label: capitalizeFirstLetter(selectedGraphType), value: selectedGraphType }}
          options={['entities', 'relations'].map(q => ({ key: q, label: capitalizeFirstLetter(q), value: q }))}
          onChange={item => {
            setSelectedType(undefined)
            setSelectedGraphType(item?.value as string)
          }}
        />
      </div>
      <div style={{ flex: '0 0 200px', marginLeft: 8 }}>
        <Select
          spacing='compact'
          placeholder='Choose the type'
          aria-label='Choose the type'
          value={{ key: selectedType?.type, label: selectedType?.name, value: selectedType?.type, schema: selectedType }}
          options={entityTypes.map(q => ({ key: q.type, label: q.name, value: q.type, schema: q }))}
          onChange={item => setSelectedType(item?.schema)}
        />
      </div>
      <div style={{ flex: '0 0 200px', marginLeft: 8 }}>
        <TextField
          isCompact
          placeholder='Filter'
          aria-label='Filter'
          elemAfterInput={<SearchIcon label='filter' size='small' primaryColor={N400} />}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)}
        />
      </div>
    </div>
  )

  return (
    <div>
      <Page
        title='Properties'
        pageBreadcrumbs={[
          {
            path: '/',
            text: 'Home'
          },
          {
            path: '/admin',
            text: 'Admin'
          },
          {
            path: '/admin/crm/properties',
            text: 'Properties'
          }]}
        bottomBar={barContent}
        actions={
          <Button
            appearance='primary'
            onClick={() => setSelectedProperty({})}
          >
            New property
          </Button>
        }
      >
        <DynamicTable
          isFixedSize
          isLoading={isLoading}
          emptyView={
            <EmptyState
              header='No data available here'
              imageUrl='/static/illustrations/illustration_empty_content.svg'
            />
          }
          head={{
            cells: [
              {
                key: 'name',
                content: 'Property name',
                isSortable: false
              },
              {
                key: 'type',
                content: 'Type'
              },
              {
                key: 'id',
                content: 'Id'
              },
              {
                key: 'fieldType',
                content: 'Field Type'
              },
              {
                key: 'description',
                content: 'Description'
              },
              {
                key: 'actions',
                content: undefined
              }
            ]
          }}
          rows={filteredProps.map((item: EntityPropertySchema, index: number) => ({
            key: `row-${index}`,
            cells: [
              {
                key: `row-${index}-name`,
                content: item.name
              },
              {
                key: `row-${index}-type`,
                content: item.type
              },
              {
                key: `row-${index}-id`,
                content: item.id
              },
              {
                key: `row-${index}-fieldType`,
                content: PropertyFieldConfiguration[item.veiveFieldType ?? PropertyFieldType.text]?.label
              },
              {
                key: `row-${index}-description`,
                content: item.description,
                shouldTruncate: true
              },
              {
                key: `row-${index}-actions`,
                content:
  <div style={{ display: 'flex', justifyContent: 'right' }}>
    <DropdownMenu
      placement='bottom-end'
      trigger={({ triggerRef, ...props }) => (
        <Button
          {...props}
          appearance='subtle'
          iconBefore={<MoreIcon label='more' />}
          ref={triggerRef}
        />
      )}
    >
      <DropdownItemGroup>
        <DropdownItem
          isDisabled={item.readOnlyDefinition || isSaving}
          onClick={() => {
            setEditMode(true)
            setSelectedProperty(item)
          }}
        >Edit
        </DropdownItem>
        <DropdownItem
          isDisabled={item.readOnlyDefinition || isSaving}
          onClick={async () => await deleteProperty(item.id ?? '')}
        >Delete
        </DropdownItem>
      </DropdownItemGroup>
    </DropdownMenu>
  </div>
              }
            ]
          }))}
        />
      </Page>
      <div style={{
        display: selectedProperty ? 'block' : 'none',
        position: 'fixed',
        zIndex: 501,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }}
      >
        <FlexContainer id='editor'>
          <ContentWrapper>
            <RightSidePanel
              isOpen={selectedProperty !== undefined}
              attachPanelTo='editor'
              disableEnterAnimation
            >
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ flex: 1, padding: 24, display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3 style={{ margin: 0 }}>{isEditMode ? 'Edit' : 'New'} {selectedType?.name} property</h3>
                    <Button
                      appearance='subtle'
                      iconAfter={<CrossIcon label='close' />}
                      onClick={() => closePanel()}
                    />
                  </div>
                  <PropertyEditor
                    value={selectedProperty}
                    isEdit={isEditMode}
                    properties={selectedType ? properties[selectedType?.type] : {}}
                    groups={selectedType ? groups[selectedType.type] : []}
                    onChange={(prop, isInvalid) => {
                      setSelectedProperty(prop)
                      setIsSelectedPropInvalid(isInvalid)
                    }}
                  />
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
                    <LoadingButton appearance='subtle' onClick={() => closePanel()}>Cancel</LoadingButton>
                    <LoadingButton
                      appearance='primary'
                      isLoading={isSaving}
                      isDisabled={isSelectedPropInvalid}
                      onClick={async () => await saveProperty()}
                    >Save
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </RightSidePanel>
          </ContentWrapper>
        </FlexContainer>
      </div>
      <div
        style={{
          display: selectedProperty ? 'block' : 'none',
          position: 'fixed',
          zIndex: 500,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          overflowY: 'auto',
          pointerEvents: 'initial',
          backgroundColor: 'var(--ds-blanket,rgba(9,30,66,0.54))'
        }}
      />
    </div>
  )
}
