import React, { ReactElement } from 'react'
import {
  SideNavigation,
  NavigationHeader,
  NestableNavigationContent,
  Section,
  ButtonItem,
  NestingItem,
  Header,
  LoadingItems,
  SkeletonHeadingItem,
  SkeletonItem,
  GoBackItem
} from '@atlaskit/side-navigation'
import { AppConfigDescriptor } from '../../common/types/app-config'
import { LeftSidebar } from '@atlaskit/page-layout'
import { useLocation, useNavigate } from 'react-router-dom'
import Avatar from '@atlaskit/avatar'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import PageGuard from '../../common/components/guards/page-guard'
import {
  BusinessUnitEntity
} from 'common/types/odino/built-in/entities/business-unit-entity'

interface BusinessSideNavigationProps {
  apps: AppConfigDescriptor[]
  businessUnit: BusinessUnitEntity
  isLoading?: boolean
  testId?: string
}
export const BusinessSideNavigation = ({ apps, businessUnit, isLoading, testId }: BusinessSideNavigationProps): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()

  const buildNavigationMenu = (): ReactElement[] => {
    const sections = []
    for (const app of apps) {
      const links = []
      if (app.modules?.businessPages && businessUnit) {
        for (const pageConfig of app.modules?.businessPages) {
          const link = (
            <PageGuard config={pageConfig}>
              <ButtonItem
                iconBefore={pageConfig.icon}
                key={`nav-${app.name}-${pageConfig.key}`}
                onClick={() => navigate(`/business-units/${businessUnit.id}${app.basePath}${pageConfig.path}`)}
                isSelected={location.pathname.endsWith(`${app.basePath}${pageConfig.path}`)}
              >
                {pageConfig.title}
              </ButtonItem>
            </PageGuard>
          )
          if (link && !pageConfig.isHidden) {
            links.push(link)
          }
        }
        if (links.length > 0) {
          sections.push(
            <Section title={app.name}>
              {links}
            </Section>
          )
        }
      }
    }
    return sections
  }

  const buildSettingsMenu = (): ReactElement[] => {
    const links: ReactElement[] = []
    for (const app of apps) {
      if (app.modules?.businessSettingsPages && businessUnit) {
        for (const item of app.modules?.businessSettingsPages) {
          const fullPath = `/business-units/${businessUnit.id}/settings${app.basePath}${item.path}`
          const link = (
            <PageGuard config={item}>
              <ButtonItem
                iconBefore={item.icon}
                key={`nav-${item.key}`}
                onClick={() => navigate(fullPath)}
                isSelected={location.pathname.endsWith(`settings${app.basePath}${item.path}`)}
              >
                {item.title}
              </ButtonItem>
            </PageGuard>
          )
          if (link) {
            links.push(link)
          }
        }
      }
    }
    return links
  }

  return (
    <LeftSidebar
      isFixed
      width={280}
      testId={testId ? `${testId}-sidebar` : undefined}
    >
      <SideNavigation label='Veive CRM'>
        <NavigationHeader>
          <LoadingItems
            isLoading={isLoading}
            testId={testId ? `${testId}-loading` : undefined}
            fallback={
              <>
                <SkeletonItem isShimmering hasIcon />
                <p style={{ margin: 0, paddingLeft: 40 }}><SkeletonHeadingItem /></p>
              </>
            }
          >
            <Header
              iconBefore={<Avatar
                appearance='square'
                testId={testId ? `${testId}-avatar` : undefined}
                src={businessUnit?.op_avatarUrl}
                          />}
              description={businessUnit?.op_businessType}
            >
              {businessUnit?.op_name}
            </Header>
          </LoadingItems>
        </NavigationHeader>
        <NestableNavigationContent
          initialStack={location.pathname.includes('/settings') ? ['bu-settings'] : undefined}
          testId={testId ? `${testId}-content` : undefined}
        >
          <LoadingItems
            isLoading={isLoading}
            fallback={
              <>
                <SkeletonHeadingItem isShimmering />
                <SkeletonItem isShimmering hasIcon />
                <SkeletonItem isShimmering hasIcon />
                <SkeletonItem isShimmering hasIcon />
              </>
            }
          >
            {buildNavigationMenu()}
            <Section hasSeparator>
              <NestingItem
                id='bu-settings'
                title='Settings'
                testId={testId ? `${testId}-settings` : undefined}
                iconBefore={<SettingsIcon label='Business Unit settings' />}
                overrides={{
                  GoBackItem: {
                    render: (props) =>
                      <GoBackItem
                        {...props}
                        testId={`${testId}-goback`}
                        onClick={() => {
                          navigate(`/business-units/${businessUnit.id}`)
                          // eslint-disable-next-line react/prop-types
                          props.onClick()
                        }}
                      >
                        Back to Business Unit
                      </GoBackItem>
                  }
                }}
              >
                {buildSettingsMenu()}
              </NestingItem>
            </Section>
          </LoadingItems>
        </NestableNavigationContent>
      </SideNavigation>
    </LeftSidebar>
  )
}
