import React, { ReactElement } from 'react'

interface ConditionalWrapperProps {
  condition?: boolean
  wrapper: (children: ReactElement) => ReactElement
  children: ReactElement
}

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps): ReactElement => {
  return condition ? wrapper(children) : children
}
