import React, { ReactElement } from 'react'
import { SkeletonItem } from '@atlaskit/side-navigation'
import {
  fontSize as getFontSize,
  gridSize as getGridSize
} from '@atlaskit/theme/constants'

interface PropertyGroupSkeletonProps {
  groups?: number
  propertiesPerGroup?: number
}

export const PropertyGroupSkeleton = ({ groups, propertiesPerGroup }: PropertyGroupSkeletonProps): ReactElement => {
  const fontSize = getFontSize()
  const gridSize = getGridSize()

  const readViewContainerStyles = {
    display: 'flex',
    maxWidth: '100%',
    minHeight: `${(gridSize * 2) / fontSize}em`,
    padding: `${gridSize - 2}px ${gridSize - 2}px`,
    fontSize: `${fontSize}px`,
    lineHeight: `${(gridSize * 2) / fontSize}`
  }

  return (
    <div>
      {
        Array.from(Array(propertiesPerGroup ?? 1).keys()).map(g => (
          <div key={`group-${g}`}>
            <h5 style={{ marginBottom: 16 }}><SkeletonItem cssFn={() => ({ minHeight: 0, padding: 0 })} /></h5>
            <div>
              {
                Array.from(Array(propertiesPerGroup ?? 3).keys()).map(q => (
                  <div
                    key={`group-${g}-${q}`}
                    style={{ marginTop: 8 }}
                  >
                    <label style={{ width: 150 }}><SkeletonItem cssFn={() => ({ minHeight: 0, padding: 0 })} /></label>
                    <div style={readViewContainerStyles}>
                      <p style={{ width: '100%' }}><SkeletonItem cssFn={() => ({ minHeight: 28, padding: 0 })} /></p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}
