/* istanbul ignore file */

import React from 'react'
import Spinner from '@atlaskit/spinner'

export default function LoadingScreen (): JSX.Element {
  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ width: 300, height: 300, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 50 }}>
          <img src='/logo.svg' style={{ height: 42, marginRight: 10 }} alt='Veive logo' />
          <h1 style={{ margin: 0 }}>Veive</h1>
        </div>

        <Spinner size='medium' />
      </div>
    </div>
  )
}
