import React, { Suspense, ElementType } from 'react'
import LoadingGuard from './guards/loading-guard'

export const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingGuard isLoading />}>
      <Component {...props} />
    </Suspense>
  )
}
