import React, { lazy } from 'react'
import { AppConfigDescriptor } from '../../common/types/app-config'
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building'
import EmojiFlagsIcon from '@atlaskit/icon/glyph/emoji/flags'
import { N400 } from '@atlaskit/theme/colors'
import { AdminPageProperties } from './admin-pages/admin-page-properties'
import { Navigate } from 'react-router-dom'
import { Condition } from '../../common/types/app-config/module-config'
import { BusinessPageCrmViews } from './business-pages/business-page-crm-views'
import { Loadable } from '../../common/components/loadable'
import apiFetch from '../../common/utils/apiFetch'
import { LightEntitySchema } from 'common/types/odino/entity-schema'
import DocumentsIcon from '@atlaskit/icon/glyph/documents'
import PersonCircleIcon from '@atlaskit/icon/glyph/person-circle'

const DialogCreateEntity = Loadable(lazy(async () => await import('./dialogs/dialog-create-entity')))

const iconMap: { [key: string]: React.ReactElement } = {
  contact: <PersonCircleIcon primaryColor={N400} label='contacts' />,
  company: <OfficeBuildingIcon primaryColor={N400} label='companies' />,
  deal: <EmojiFlagsIcon primaryColor={N400} label='deals' />,
  custom: <DocumentsIcon primaryColor={N400} label='custom' />
}

const CRMConfig: AppConfigDescriptor = {
  name: 'CRM',
  key: 'veive-crm',
  basePath: '/crm',
  isBuildIn: true,
  modules: {
    adminPages: [
      {
        key: 'veive-crm-settings-root',
        path: '/',
        title: 'Settings',
        isHidden: true,
        element: <Navigate replace to='/admin/crm/properties' />
      },
      {
        key: 'veive-crm-settings-properties',
        path: '/properties',
        title: 'Properties',
        element: <AdminPageProperties />
      }
    ],
    generalPages: [],
    businessPages: [],
    navigation: {
      primaryActions: []
    },
    dialogs: [
      {
        key: 'veive-crm-dialog-create-entity',
        title: 'Create Entity',
        element: <DialogCreateEntity />
      }
    ]
  }
}

export const GetCRMConfig = async (): Promise<AppConfigDescriptor> => {
  const config = CRMConfig
  const graphResponse = await apiFetch<{entities: {crm: LightEntitySchema[], system: LightEntitySchema[]}}>('graph')
  graphResponse.entities.crm.forEach((entity: LightEntitySchema) => {
    config.modules?.businessPages?.push({
      key: `veive-business-crm-${entity.type}`,
      path: `/${entity.type}`,
      title: entity.pluralName,
      icon: iconMap[entity.type] ? iconMap[entity.type] : iconMap.custom,
      element: <BusinessPageCrmViews key={`veive-crm-${entity.type}`} entityType={entity.type} title={entity.pluralName} isBusinessUnit />,
      conditions: [
        {
          condition: Condition.hasPermissionScope,
          params: { value: `${entity.type}:read` }
        }
      ]
    })
    config.modules?.generalPages?.push({
      key: `veive-crm-${entity.type}`,
      path: `/${entity.type}`,
      title: entity.pluralName,
      icon: iconMap[entity.type] ? iconMap[entity.type] : iconMap.custom,
      element: <BusinessPageCrmViews key={`veive-crm-${entity.type}`} entityType={entity.type} title={entity.pluralName} />,
      conditions: [
        {
          condition: Condition.hasPermissionScope,
          params: { value: `${entity.type}:read` }
        }
      ]
    })
    config.modules?.navigation?.primaryActions?.push({
      key: `veive-actions-crm-${entity.type}`,
      title: entity.pluralName,
      path: `/${entity.type}`,
      icon: iconMap[entity.type] ? iconMap[entity.type] : iconMap.custom,
      conditions: [
        {
          condition: Condition.hasPermissionScope,
          params: { value: `${entity.type}:read` }
        }
      ]
    })
  })
  return config
}
