import React, { useEffect, useState } from 'react'
import { Page } from '../../../common/components/pages/page'
import Button from '@atlaskit/button'
import EmptyState from '@atlaskit/empty-state'
import apiFetch from '../../../common/utils/apiFetch'
import { useNavigate } from 'react-router-dom'
import { EntityList } from '../../crm/components/entity-list/entity-list'
import { Entity, EntitySummary } from 'common/types/odino/entity'
import useDialog from '../../../common/hooks/use-dialog/useDialog'
import useEntityCache from '../../../common/hooks/use-cache/useEntityCache'
import { VeiveUser } from 'common/types/current-user'

export const GeneralPageBusinessUnitsList = () => {
  const [users, setUsers] = useState<VeiveUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dialog = useDialog()
  const cache = useEntityCache('business-unit', true)
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    const [usersResponse] = await Promise.all([apiFetch('users')])
    // setEntities(buResponse.results ?? [])
    setUsers(usersResponse.users)
    setIsLoading(false)
  }

  const openCreateDialog = (): void => {
    dialog.open({
      key: 'veive-crm-dialog-create-entity',
      input: {
        title: 'Create Business Unit',
        entityType: 'business-unit'
      },
      onClose: ({ reload }) => {
        if (reload) {
          fetchData()
        }
      }
    })
  }

  return (
    <Page
      title='Business Units'
      actions={
        cache.getEntities().length > 0
          ? <Button
              appearance='primary'
              onClick={() => openCreateDialog()}
            >
            Create Business Unit
          </Button>
          : undefined
      }
    >
      <EntityList
        entityType='business-unit'
        properties={['op_businessType', 'op_description']}
        entities={cache.getEntities()}
        isLoading={isLoading || cache.isLoading}
        onSummaryClick={(summary) => navigate(`/business-units/${summary?.id}`)}
        actions={[
          {
            label: 'Settings',
            onClick: (entity: Entity) => { navigate(`/business-units/${entity.id}/settings/business-units/details`) }
          }
        ]}
        emptyView={
          <EmptyState
            header="You don't have any Business Unit"
            description='Create a new business unit to manage your contacts, deals and your marketing campaigns'
            imageUrl='/static/illustrations/illustration_empty_content.svg'
            primaryAction={
              <Button
                appearance='primary'
                onClick={() => openCreateDialog()}
              >
                Create Business Unit
              </Button>
            }
          />
        }
      />
    </Page>
  )
}
