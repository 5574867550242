/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import DynamicTable from '@atlaskit/dynamic-table'
import Pagination from '@atlaskit/pagination'
import { HeadType, RowType } from '@atlaskit/dynamic-table/types'
import EmptyState from '@atlaskit/empty-state'
import useSchema from '../../../../common/hooks/use-schema/useSchema'
import { Entity, EntitySummary } from 'common/types/odino/entity'
import { PropertyRenderer } from '../renderers/property-renderer'
import { SummaryRenderer } from '../renderers/summary-renderer'
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu'
import Button from '@atlaskit/button'
import MoreIcon from '@atlaskit/icon/glyph/more'
import { PropertyRenderType } from 'common/types/crm/property-schema'
import { css, jsx } from '@emotion/core'
import { token } from '@atlaskit/tokens'

interface EntityAction {
  label: string
  onClick: (entity: Entity) => void
  isDisabled?: (entity: Entity) => boolean
}

interface EntityListProps {
  actions?: EntityAction[]
  emptyView?: React.ReactElement
  entities?: Entity[]
  entityType: string
  hideSummary?: boolean
  isLoading?: boolean
  onPageSelected?: (index: number) => void
  onSummaryClick?: (summary?: EntitySummary) => void
  properties: string[]
  rowsPerPage?: number
  selectedPage?: number
  testId?: string
  totalItems?: number
}

export const EntityList = (props: EntityListProps): React.ReactElement => {
  const [schema, isSchemaLoading] = useSchema(props.entityType)

  const rowsDivider = props.rowsPerPage ?? 10
  const totalItems = props.totalItems ?? 0
  const totalPages = Math.ceil(totalItems / rowsDivider)
  const pageArray = []
  for (let i = 0; i < totalPages; i++) {
    pageArray.push(i + 1)
  }

  const getHeader = (): HeadType | undefined => {
    if (props.properties && schema?.properties) {
      const header: HeadType = { cells: [] }
      if (!props.hideSummary) {
        header.cells.push({
          key: 'title',
          content: 'Entity'
        })
      }
      header.cells.push(...props.properties.map(q => ({
        key: q,
        content: schema.properties[q]?.name ?? q
      })))
      if (props.actions) {
        header.cells.push({
          key: 'actions',
          content: ''
        })
      }
      return header
    }
    return undefined
  }

  const getRows = (): RowType[] | undefined => {
    if (props.entities && props.entities.length > 0 && schema?.properties) {
      return props.entities.map((item, index) => {
        const row: RowType = {
          key: `row-${index}`,
          cells: []
        }
        if (!props.hideSummary) {
          row.cells.push({
            key: `row-${index}-title`,
            content: <SummaryRenderer
              summary={item.summary}
              onClick={() => {
                props.onSummaryClick?.(item.summary)
              }}
                     />
          })
        }
        row.cells.push(...props.properties.map((q, colIndex) => ({
          key: `row-${index}-${colIndex}`,
          content: <PropertyRenderer
            schema={schema.properties[q]}
            value={item[q]}
            onClick={(summary) => {
              if (schema.properties[q].veiveRenderType === PropertyRenderType.relation) {
                props.onSummaryClick?.(summary)
              }
            }}
                   />
        })))
        if (props.actions) {
          row.cells.push({
            key: `row-${index}-actions`,
            content: (
              <div
                style={{ display: 'flex', justifyContent: 'right' }}
              >
                <DropdownMenu
                  placement='bottom-end'
                  trigger={({ triggerRef, ...props }) => (
                    <Button
                      {...props}
                      appearance='subtle'
                      iconBefore={<MoreIcon label='more' />}
                      ref={triggerRef}
                    />
                  )}
                >
                  {
                    props.actions.map((action, actionIndex) => {
                      const isDisabled = action.isDisabled ? action.isDisabled(item) : false
                      return (
                        <DropdownItemGroup
                          key={`row-${index}-actions-${actionIndex}`}
                        >
                          <DropdownItem
                            onClick={() => action.onClick(item)}
                            isDisabled={isDisabled}
                          >
                            {action.label}
                          </DropdownItem>
                        </DropdownItemGroup>
                      )
                    })
                  }
                </DropdownMenu>
              </div>
            )
          })
        }
        return row
      })
    }
    return undefined
  }

  const wrapperStyles = css({
    table: {
      width: 'auto',
      minWidth: '100%',
      td: {
        minWidth: '150px'
      }
    }
  })
  const overflow = css({
    overflowX: 'auto',
    padding: '4px 6px',
    '& > div:first-child': {
      marginBottom: 8
    },
    '::after': {
      width: 8,
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 'calc(100% - 8px)',
      background: `linear-gradient(to right, ${token(
        'color.blanket',
        'rgba(99, 114, 130, 0)'
      )} 0px, ${token('color.blanket', 'rgba(9, 30, 66, 0.13)')} 100%)`,
      content: "''"
    }
  })

  return (
    <div css={wrapperStyles}>
      <div css={overflow}>
        <DynamicTable
          testId={props.testId}
          head={getHeader()}
          rows={getRows()}
          isLoading={isSchemaLoading || props.isLoading}
          isFixedSize
          emptyView={
            props.emptyView ?? (
              <EmptyState
                header='No data available here'
                imageUrl='/static/illustrations/illustration_empty_content.svg'
              />)
          }
        />
      </div>
      {
        props.totalItems && props.rowsPerPage && props?.totalItems > props.rowsPerPage &&
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
            <Pagination
              pages={pageArray}
              max={8}
              selectedIndex={props.selectedPage}
              onChange={(e, page) => props.onPageSelected?.(page - 1)}
            />
          </div>
      }
    </div>
  )
}
