import React from 'react'
import { useRecoilState } from 'recoil'
import { rightPanelOpenState, rightPanelRenderState } from '../../recoil/right-panel'

interface RightPanelManager {
  close: () => void
  isOpen: boolean
  open: (func: () => React.ReactNode) => void
  update: (func: () => React.ReactNode) => void
  options?: UseRightPanelOptions
  render: (() => React.ReactNode) | undefined
}

interface UseRightPanelOptions {
  onOpen?: (component: React.ReactNode) => void
  width?: number
}

const useRightPanel = (options?: UseRightPanelOptions): RightPanelManager => {
  const [isOpen, setIsOpen] = useRecoilState(rightPanelOpenState)
  const [renderFunc, setRenderFunc] = useRecoilState<(() => React.ReactNode) | undefined>(rightPanelRenderState)

  const open = (renderFunc: () => React.ReactNode): void => {
    setIsOpen(true)
    setRenderFunc(renderFunc)
  }

  const update = (renderFunc: () => React.ReactNode): void => {
    setRenderFunc(renderFunc)
  }

  const close = (): void => {
    setIsOpen(false)
    setRenderFunc(undefined)
  }

  return {
    // children,
    close,
    isOpen,
    open,
    update,
    options: options,
    render: renderFunc
  }
}

export default useRightPanel
