import React from 'react'
import { AppConfigDescriptor } from '../../common/types/app-config'
import { AdminPageUsers } from './admin-pages/admin-page-users'
import { AdminPageRoles } from './admin-pages/admin-page-roles'
import { Navigate } from 'react-router-dom'

export const GlobalSettingsConfig: AppConfigDescriptor = {
  name: 'Global Settings',
  key: 'veive-global-settings',
  basePath: '/settings',
  modules: {
    adminPages: [
      {
        key: 'veive-global-settings-root',
        path: '/',
        title: 'Settings',
        isHidden: true,
        element: <Navigate replace to='/admin/settings/users' />
      },
      {
        key: 'veive-global-settings-users',
        path: '/users',
        title: 'Users',
        element: <AdminPageUsers />
      },
      {
        key: 'veive-global-settings-roles',
        path: '/roles',
        title: 'Roles',
        element: <AdminPageRoles />
      }
    ]
  }
}
