import React from 'react'
import { AppConfigDescriptor } from '../../common/types/app-config'
import { GeneralPageBusinessUnitsList } from './general-pages/general-page-business-units-list'
import { SettingsDetails } from './business-settings-pages/settings-details'
import { NavigationBusinessUnits } from './navigation/navigation-business-units'
import { BusinessUnitsIndexRedirect } from './business-units-index-redirect'

export const BusinessUnitsConfig: AppConfigDescriptor = {
  name: 'Business Units',
  key: 'veive-business-units',
  basePath: '/business-units',
  isBuildIn: true,
  modules: {
    generalPages: [
      {
        key: 'veive-business-units-list',
        path: '/',
        title: 'Business Units',
        element: <GeneralPageBusinessUnitsList />
      }
    ],
    businessSettingsPages: [
      {
        key: 'veive-business-units-settings-details',
        path: '/details',
        title: 'Details',
        element: <SettingsDetails />
      }
    ],
    navigation: {
      primaryActions: [
        {
          key: 'veive-actions-business-units-nav',
          title: 'Business Units',
          element: <NavigationBusinessUnits key='veive-actions-business-units-nav' />
        }
      ]
    }
  }
}
