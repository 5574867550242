import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import useBusinessUnit
  from '../../../common/hooks/use-business-units/useBusinessUnit'
import { Page } from '../../../common/components/pages/page'
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button'
import Tabs, { Tab, TabList, TabPanel, useTab } from '@atlaskit/tabs'
import { View } from 'common/types/odino/view'
import apiFetch, {
} from '../../../common/utils/apiFetch'
import AddIcon from '@atlaskit/icon/glyph/add'
import { ViewExplorer } from '../components/views/view-explorer'
import Tooltip from '@atlaskit/tooltip'
import useDialog from '../../../common/hooks/use-dialog/useDialog'
import { Entity, EntitySummary } from 'common/types/odino/entity'
import useViews from '../../../common/hooks/use-views/useViews'
import { useDebounce } from 'use-debounce'
import {
  EntityPanel
} from '../components/entity-panel/entity-panel'
import equal from 'fast-deep-equal'
import useRightPanel from '../../../common/hooks/use-right-panel/useRightPanel'
import {
  convertToUpdateRequestFormat
} from '../components/property-field/field-utils'
import { useFlags } from '@atlaskit/flag'
import { token } from '@atlaskit/tokens'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'

interface BusinessPageCrmViewsProps {
  entityType: string
  title: string
  isBusinessUnit?: boolean
}

const TabButton = ({ icon, tooltip, onClick }: { icon: ReactElement, tooltip?: string, onClick?: () => void}) => {
  const { onKeyDown, tabIndex, ...tabAttributes } = useTab()
  return (
    <span {...tabAttributes} onClick={onClick}>
      <Tooltip content={tooltip}>
        <Button iconAfter={icon} appearance='subtle' spacing='compact' />
      </Tooltip>
    </span>
  )
}

export const BusinessPageCrmViews = ({ entityType, title, isBusinessUnit }: BusinessPageCrmViewsProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [listViews, setListViews] = useState<View[]>([])
  const [selectedListView, setSelectedListView] = useState<View>()
  const [entities, setEntities] = useState<{results: Entity[], total: number}>({ results: [], total: 0 })
  const [isEntitiesLoading, setIsEntitiesLoading] = useState<boolean>(false)
  const [selectedSummary, setSelectedSummary] = useState<EntitySummary | undefined>(undefined)
  const [selectedEntity, setSelectedEntity] = useState<Entity | undefined>(undefined)
  const [panelView, setPanelView] = useState<View | undefined>(undefined)
  const [isPanelLoading, setIsPanelLoading] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [debouncedSearch] = useDebounce(searchQuery?.toLowerCase(), 500)
  const [selectedPage, setSelectedPage] = useState<number>(0)
  const rowsPerPage = 20

  const [businessUnit] = useBusinessUnit()
  const dialog = useDialog()
  const views = useViews()
  const rightPanel = useRightPanel()
  const flags = useFlags()

  useEffect(() => {
    if (isBusinessUnit) {
      if (businessUnit) {
        updateRootViews()
      }
    } else {
      updateRootViews()
    }
  }, [businessUnit, entityType])

  useEffect(() => {
    if (selectedListView) {
      refreshEntities()
    }
  }, [selectedListView, debouncedSearch, selectedPage])

  useEffect(() => {
    if (selectedEntity && panelView) {
      rightPanel.open(renderRightPanel)
    }
  }, [selectedEntity, isPanelLoading, panelView])

  const updateRootViews = async () => {
    setEntities({ results: [], total: 0 })
    setIsLoading(true)
    const rootViews = await views.getViews(entityType)
    const defaultListViewId = rootViews?.config?.list?.[0]
    setSelectedListView(rootViews.views[defaultListViewId])
    setListViews(Object.values(rootViews.views).filter(view => rootViews.config.list.includes(view.id)))
    setPanelView(rootViews.views[defaultListViewId])
    setIsLoading(false)
  }

  const refreshEntities = async () => {
    setIsEntitiesLoading(true)
    const response = await apiFetch(`graph/entities/${entityType}?view=${selectedListView?.id}&query=${debouncedSearch}&limit=${rowsPerPage}&skip=${rowsPerPage * selectedPage}`
    )
    setEntities(response)
    setIsEntitiesLoading(false)
  }

  const handleSummaryClick = async (summary?: EntitySummary) => {
    if (summary?.type && summary?.id) {
      setIsPanelLoading(true)
      setSelectedSummary(summary)
      const entityViews = await views.getViews(summary.type)
      if (entityViews?.config.panel) {
        setPanelView(entityViews.views[entityViews.config.panel])
      }
      // Get Entity
      const entity: Entity = await apiFetch(`graph/entities/${summary.type}/${summary.id}?view=${entityViews?.config.panel}`)
      setSelectedEntity(entity)
      setIsPanelLoading(false)
    }
  }

  const updateEntity = async (entity?: Entity) => {
    if (entity && !equal(entity, selectedEntity)) {
      const response = await apiFetch(`graph/entities/${entity.type}/${entity.id}`, {
        method: 'PUT',
        body: JSON.stringify(convertToUpdateRequestFormat(entity))
      })
      setSelectedSummary(response.summary)
      setSelectedEntity(response)
      flags.showFlag({
        icon: <CheckCircleIcon label='Success' primaryColor={token('color.icon.success', '#22A06B')} />,
        title: 'Entity updated',
        description: 'Entity updated successfully',
        isAutoDismiss: true
      })
      await refreshEntities()
    }
  }

  const renderRightPanel = (): ReactNode => {
    return (
      <EntityPanel
        key={Math.floor(Math.random() * 100)}
        entity={selectedEntity}
        view={panelView}
        summary={selectedSummary}
        onCloseClick={() => {
          setSelectedSummary(undefined)
          setSelectedEntity(undefined)
          rightPanel.close()
        }}
        onEntityChange={updateEntity}
        isLoading={isPanelLoading}
      />
    )
  }

  return (
    <Page
      title={title}
      isLoading={isLoading}
      css={{ paddingBottom: 24, flex: 1 }}
      actions={
        <ButtonGroup>
          <LoadingButton
            appearance='primary'
            onClick={() => dialog.open({
              key: 'veive-crm-dialog-create-entity',
              input: { entityType: entityType },
              onClose: async ({ reload }) => {
                if (reload) {
                  await refreshEntities()
                }
              }
            })}
          >
            Create {entityType}
          </LoadingButton>
        </ButtonGroup>
      }
    >
      <div>
        <Tabs
          onChange={(index) => setSelectedListView(listViews?.[index])}
          id='default'
        >
          <TabList>
            {
              listViews?.map((view, index) =>
                (<Tab key={index}>{view.name}</Tab>))
            }
            <TabButton
              icon={<AddIcon label='Add' size='small' />}
              tooltip='Create view'
            />
          </TabList>
          {listViews?.map((view, index) => (
            <TabPanel key={`tab${index}`}>
              <div style={{ marginTop: 24, width: '100%' }}>
                <ViewExplorer
                  view={view}
                  entities={entities.results}
                  totalItems={entities.total}
                  rowsPerPage={rowsPerPage}
                  isLoading={isEntitiesLoading || isLoading}
                  onSearchChange={(query) => setSearchQuery(query)}
                  onSummaryClick={handleSummaryClick}
                  selectedPage={selectedPage}
                  onPageSelected={(page) => setSelectedPage(page)}
                />
              </div>
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </Page>
  )
}
