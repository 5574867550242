import React from 'react'
import Spinner from '@atlaskit/spinner'

interface LoadingGuardProps {
  isLoading: boolean
  children?: React.ReactNode
  width?: number | string
  height?: number | string
}
const LoadingGuard = ({ isLoading, children, width, height }: LoadingGuardProps): JSX.Element => {
  if (isLoading) {
    return (
      <div style={{ display: 'flex', width: width ?? '100%', height: height ?? '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Spinner />
      </div>
    )
  }
  return <>{children}</>
}

export default LoadingGuard
