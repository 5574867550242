import * as React from 'react'
import {
  Navigate,
  RouteObject,
  useRoutes
} from 'react-router-dom'
import { PageError } from './common/components/pages/page-error'
import ShellApp from './shell/shell-app'
import LoadingScreen from './common/components/loading-screen'
import { Shell } from './shell'
import AuthGuard from './common/components/guards/auth-guard'
import { AppList } from './apps'
import { PageConfig } from './common/types/app-config/page-config'
import { BusinessUnitsIndexRedirect } from './apps/business-units/business-units-index-redirect'
import { useEffect } from 'react'
import { AppConfigDescriptor } from './common/types/app-config'

export default function Router (): React.ReactElement | null {
  const [childRoutes, setChildRoutes] = React.useState<RouteObject[]>([])
  const [appConfigs, setAppConfigs] = React.useState<AppConfigDescriptor[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const pageModules: Array<{moduleKey: string, moduleBasePath: string}> = [
    { moduleKey: 'generalPages', moduleBasePath: '' },
    { moduleKey: 'businessPages', moduleBasePath: '/business-units/:businessKey' },
    { moduleKey: 'adminPages', moduleBasePath: '/admin' },
    { moduleKey: 'businessSettingsPages', moduleBasePath: '/business-units/:businessKey/settings' }]

  useEffect(() => {
    const resolveAppConfigs = async () => {
      const configs: AppConfigDescriptor[] = []
      for (const app of AppList) {
        configs.push(await app)
      }
      setAppConfigs(configs)
    }
    resolveAppConfigs()
  }, [])

  useEffect(() => {
    updateChildRoutes()
  }, [appConfigs])

  const updateChildRoutes = async (): Promise<void> => {
    let newRoutes: RouteObject[] = []
    for (const appConfig of appConfigs) {
      for (const mod of pageModules) {
        // @ts-expect-error
        if (appConfig.modules[mod.moduleKey]) {
          // @ts-expect-error
          newRoutes = [...newRoutes, ...appConfig.modules[mod.moduleKey].map((q: PageConfig) => ({
            path: mod.moduleBasePath + appConfig.basePath + q.path,
            element: q.element
          }))]
        }
      }
    }
    setChildRoutes(newRoutes)
    setIsLoading(false)
  }

  const routes = [
    {
      path: '/',
      element: (
        <AuthGuard>
          <Shell apps={appConfigs} />
        </AuthGuard>
      ),
      children: [
        ...[{ path: '/', element: <ShellApp /> }],
        { path: '/403', element: <PageError code={403} /> },
        { path: '/404', element: <PageError code={404} /> },
        {
          path: '/admin',
          element: <Navigate to='/admin/settings/users' replace />
        },
        {
          path: '/business-units/:businessKey',
          element: <BusinessUnitsIndexRedirect />
        },
        ...childRoutes,
        {
          path: '*',
          element: <Navigate to='/404' replace />
        }
      ]
    },
    { path: '/loading', element: <LoadingScreen /> }
  ]

  const rt = useRoutes(routes)

  if (isLoading) {
    return (<LoadingScreen />)
  } else {
    return rt
  }
}
