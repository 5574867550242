import React from 'react'
import usePermissions from '../../hooks/use-permissions/usePermissions'
interface AdminGuardProps {
  children: React.ReactNode
}
const AdminGuard = ({ children }: AdminGuardProps): JSX.Element | null => {
  const permissions = usePermissions()
  if (!permissions.isAdmin()) {
    return null
  }
  return <>{children}</>
}

export default AdminGuard
