import React, { ReactNode, useEffect, useState } from 'react'
import {
  Content,
  Main,
  PageLayout,
  RightSidebar,
  TopNavigation
} from '@atlaskit/page-layout'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import {
  AppConfigDescriptor
} from '../common/types/app-config'
import { AdminSideNavigation } from './side-navigations/admin-side-navigation'
import { BusinessSideNavigation } from './side-navigations/business-side-navigation'
import useBusinessUnit from '../common/hooks/use-business-units/useBusinessUnit'
import { VeiveTopNavigation } from './top-navigation/veive-top-navigation'
import { DialogManager } from './dialog/dialog-manager'
import useEntityCache from '../common/hooks/use-cache/useEntityCache'
import useRightPanel from '../common/hooks/use-right-panel/useRightPanel'

interface ShellProps {
  apps: AppConfigDescriptor[]
}

export const Shell = ({ apps }: ShellProps) => {
  const location = useLocation()
  const { businessKey } = useParams()
  const [businessUnit, setBusinessUnit] = useBusinessUnit()
  const [sidebarLoading, setSidebarLoading] = useState(false)
  const [rightPanelChild, setRightPanelChild] = useState<ReactNode | null>(null)
  const cache = useEntityCache('business-unit', true)
  const rightPanel = useRightPanel()
  console.log('P', rightPanel)
  useEffect(() => {
    setSidebarLoading(cache.isLoading)
    const entity = cache.getEntity(businessKey ?? '')
    if (businessKey && entity) {
      setBusinessUnit(entity)
    }
  }, [businessKey, cache.isLoading])

  const buildSideBar = () => {
    if (location.pathname.startsWith('/admin')) {
      return <AdminSideNavigation apps={apps} />
    }
    if (location.pathname.startsWith('/business-units') && businessKey) {
      return (
        <BusinessSideNavigation
          apps={apps}
          businessUnit={businessUnit}
          isLoading={sidebarLoading}
        />
      )
    }

    return null
  }

  return (
    <PageLayout>
      <TopNavigation
        isFixed
      >
        <VeiveTopNavigation apps={apps} />
      </TopNavigation>
      <Content>
        {buildSideBar()}
        <Main>
          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <Outlet />
          </div>
        </Main>
        {
          rightPanel.isOpen && (
            <RightSidebar
              isFixed
              width={rightPanel?.options?.width ?? 320}
            >
              <div style={{ borderLeft: '2px solid #EBECF0', padding: '0 12px', height: '100%', overflow: 'hidden' }}>
                {
                  rightPanel.render
                }
              </div>
            </RightSidebar>
          )
        }
      </Content>
      <DialogManager apps={apps} />
    </PageLayout>
  )
}
