import { useRecoilState, SetterOrUpdater } from 'recoil'
import {
  businessUnitsState
} from '../../recoil/business-unit'
import {
  BusinessUnitEntity
} from 'common/types/odino/built-in/entities/business-unit-entity'

// ----------------------------------------------------------------------

const useBusinessUnit = (): [BusinessUnitEntity, SetterOrUpdater<BusinessUnitEntity>] => {
  return useRecoilState<BusinessUnitEntity>(businessUnitsState)
}

export default useBusinessUnit
