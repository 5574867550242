import { useRecoilState } from 'recoil'
import apiFetch from '../../utils/apiFetch'
import { View } from 'common/types/odino/view'
import { entityViewsState } from '../../recoil/views'

export interface ViewConfig {
  list: string[]
  panel?: string
}
export interface EntityViews {
  views: {[key: string]: View}
  config: ViewConfig
}

export interface ViewManager {
  getViews: (entityType: string) => Promise<EntityViews>
}

const useEntityViews = (): ViewManager => {
  const [viewContainer, setViewContainer] = useRecoilState(entityViewsState)

  const getViews = async (entityType: string): Promise<EntityViews> => {
    if (viewContainer[entityType]) {
      return viewContainer[entityType]
    }
    const newContainer = { ...viewContainer }
    const response: {views: View[]} = await apiFetch(`graph/views/entities/${entityType}`)
    const newEntityViews: EntityViews = {
      views: response.views.reduce((result: {[key: string]: View}, item: View) => {
        result[item.id] = item
        return result
      }, {}),
      config: {
        list: response.views.filter(v => v.viewType === 'list').map(q => q.id) ?? [],
        panel: response.views.find(v => v.viewType === 'panel')?.id
      }
    }
    newContainer[entityType] = newEntityViews
    setViewContainer(newContainer)
    return newEntityViews
  }

  return {
    getViews: getViews
  }
}
export default useEntityViews
