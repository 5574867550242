import React from 'react'
import { useRecoilState } from 'recoil'
import { DialogOptions } from '../../common/types/dialog/dialog-options'
import { dialogState } from '../../common/recoil/dialog'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'
import { AppConfigDescriptor } from '../../common/types/app-config'
import { DialogConfig } from '../../common/types/app-config/dialog-config'
import EmptyState from '@atlaskit/empty-state'

interface DialogManagerProps {
  apps: AppConfigDescriptor[]
}
export const DialogManager = ({ apps }: DialogManagerProps): React.ReactElement => {
  const [dialog, setDialog] = useRecoilState<DialogOptions | undefined>(dialogState)
  const dialogMap: { [key: string]: DialogConfig } = {}
  apps.forEach(app => {
    app.modules?.dialogs?.forEach(dialog => {
      dialogMap[dialog.key] = dialog
    })
  })

  const closeDialog = (output: any): void => {
    dialog?.onClose?.(output)
    setDialog(undefined)
  }

  const DialogContent = (): JSX.Element => {
    if (dialog?.key && dialogMap[dialog.key]) {
      return React.cloneElement(dialogMap[dialog.key].element, { input: dialog.input, onClose: closeDialog })
    }
    return (
      <EmptyState
        header='Dialog Key not found'
        description='Make sure the dialog exists in your app config.'
        imageUrl='/static/illustrations/illustration_empty_content.svg'
      />
    )
  }

  return (
    <ModalTransition>
      {dialog && (
        <Modal
          shouldCloseOnOverlayClick={false}
          width={dialogMap[dialog?.key]?.width ?? 'medium'}
          height={dialogMap[dialog?.key]?.height ?? undefined}
          onClose={closeDialog}
        >
          <DialogContent />
        </Modal>
      )}
    </ModalTransition>
  )
}
