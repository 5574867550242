import { useRecoilState } from 'recoil'
import {
  createLocalizationProvider,
  LocalizationProvider
} from '@atlaskit/locale'
import { localeState } from '../../recoil/locale'

interface LocaleManager {
  code: string
  provider: LocalizationProvider
}

const useLocale = (): LocaleManager => {
  // Default en-us
  const [localCode, setLocalCode] = useRecoilState(localeState)
  const provider = createLocalizationProvider(localCode)

  // TODO: Add useEffect to set locale from user settings
  return {
    code: localCode,
    provider
  }
}

export default useLocale
