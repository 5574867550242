import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import apiFetch from '../../utils/apiFetch'
import { Entity } from 'common/types/odino/entity'
import { entityCacheState } from '../../recoil/cache'

export interface EntityCache {
  [key: string]: Entity
}

export interface RefreshOptions {
  entityId?: string
  preload?: boolean
}

export interface EntityCacheManager {
  getEntity: (entityId: string) => Entity | undefined
  getOrFetchEntity: (entityId: string) => Promise<Entity | undefined>
  setEntity: (entity: Entity) => void
  deleteEntity: (entityId: string) => void
  getEntityMap: () => EntityCache
  getEntities: () => Entity[]
  setEntities: (entities: Entity[]) => void
  clear: () => void
  refresh: (options?: RefreshOptions) => Promise<void>
  isLoading: boolean
}

const useEntityCache = (entityType: string, preload: boolean = false): EntityCacheManager => {
  const [cache, setCache] = useRecoilState(entityCacheState)
  const [isLoading, setIsLoading] = React.useState(false)
  const cacheManager: EntityCacheManager = {
    getEntity: (entityId: string) => {
      // TODO: load entity from API if not in cache
      return cache[entityType]?.[entityId]
    },
    getOrFetchEntity: async (entityId: string) => {
      let entity = cache[entityType]?.[entityId]
      if (entity) {
        return entity
      } else {
        entity = await getEntity(entityId)
      }
      return entity
    },
    setEntity: (entity: Entity) => {
      if (entity?.id) {
        const newCache = { ...cache }
        if (!newCache[entityType]) {
          newCache[entityType] = {}
        }
        newCache[entityType] = { ...newCache[entityType], ...{ [entity.id]: entity } }
        setCache(newCache)
      } else {
        throw new Error('Entity must have an id')
      }
    },
    deleteEntity: (entityId: string) => {
      const newCache = { ...cache }
      if (newCache[entityType]) {
        const newEntityCache = { ...newCache[entityType] }
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete newEntityCache[entityId]
        newCache[entityType] = newEntityCache
      }
      setCache(newCache)
    },
    getEntityMap: () => {
      return cache[entityType] ?? {}
    },
    getEntities: () => {
      return Object.values(cache[entityType] ?? {})
    },
    setEntities: (entities: Entity[]) => {
      const newCache = { ...cache }
      if (!newCache[entityType]) {
        newCache[entityType] = {}
      }
      entities.forEach(entity => {
        if (entity?.id) {
          newCache[entityType][entity.id] = entity
        }
      })
      setCache(newCache)
    },
    clear: () => {
      const newCache = { ...cache }
      newCache[entityType] = {}
      setCache(newCache)
    },
    refresh: async (options?: RefreshOptions) => {
      if (options?.preload) {
        await preloadEntities()
      }
    },
    isLoading: isLoading
  }

  useEffect(() => {
    if (preload && !cache[entityType]) {
      preloadEntities()
    }
  }, [])

  const getEntity = async (entityId: string): Promise<Entity> => {
    setIsLoading(true)
    const response: Entity = await apiFetch<Entity>(`graph/entities/${entityType}/${entityId}`)
    if (response) {
      cacheManager.setEntity(response)
    }
    setIsLoading(false)
    return response
  }

  const preloadEntities = async (): Promise<void> => {
    setIsLoading(true)
    const response = await apiFetch<{results: Entity[]}>(`graph/entities/${entityType}`)
    if (response?.results) {
      cacheManager.setEntities(response.results)
    }
    setIsLoading(false)
  }

  return cacheManager
}

export default useEntityCache
