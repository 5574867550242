import React, { ChangeEvent } from 'react'
import { View } from 'common/types/odino/view'
import Textfield from '@atlaskit/textfield'
import { ButtonGroup, LoadingButton } from '@atlaskit/button'
import SearchIcon from '@atlaskit/icon/glyph/search'
import { N400 } from '@atlaskit/theme/colors'
import EditIcon from '@atlaskit/icon/glyph/edit'
import {
  MoreDropdownMenu
} from '../../../../common/components/dropdown/more-dropdown-menu'
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import { Entity, EntitySummary } from 'common/types/odino/entity'
import { EntityList } from '../entity-list/entity-list'

interface ViewExplorerProps {
  entities?: Entity[]
  isLoading?: boolean
  onEditViewClick?: () => void
  onPageSelected?: (index: number) => void
  onSearchChange?: (search: string) => void
  onSummaryClick?: (summary?: EntitySummary) => void
  rowsPerPage?: number
  selectedPage?: number
  testId?: string
  totalItems?: number
  view: View
}

export const ViewExplorer = (props: ViewExplorerProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: 380, display: 'flex', alignItems: 'center' }}>
          <span style={{ flex: 1 }}>
            <Textfield
              testId={`${props.testId}--search`}
              isCompact
              placeholder='Search..'
              elemAfterInput={<SearchIcon label='Search' primaryColor={N400} />}
              onChange={(e: ChangeEvent<HTMLInputElement>) => props.onSearchChange?.(e.target.value)}
            />
          </span>
          <h6 style={{ marginLeft: 12, marginTop: 0 }}>{props.totalItems} results</h6>
        </div>
        <div>
          <ButtonGroup>
            <LoadingButton
              testId={`${props.testId}--edit-view`}
              appearance='subtle'
              iconAfter={<EditIcon label='Edit' />}
              onClick={props.onEditViewClick}
            />
            <MoreDropdownMenu buttonAppearance='subtle'>
              <DropdownItemGroup>
                <DropdownItem isDisabled>Delete view</DropdownItem>
              </DropdownItemGroup>
            </MoreDropdownMenu>
          </ButtonGroup>
        </div>
      </div>
      <div style={{ marginTop: 40 }}>
        <EntityList
          entities={props.entities}
          entityType={props.view.entityType}
          isLoading={props.isLoading}
          onPageSelected={props.onPageSelected}
          onSummaryClick={props.onSummaryClick}
          properties={[...props.view.groups?.og_all ?? []]}
          rowsPerPage={props.rowsPerPage}
          selectedPage={props.selectedPage}
          testId={props.testId}
          totalItems={props.totalItems}
        />
      </div>
    </div>
  )
}
