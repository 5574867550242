import React, { ReactNode } from 'react'
import { LazyMotion } from 'framer-motion'

// ----------------------------------------------------------------------

// eslint-disable-next-line import/extensions,@typescript-eslint/explicit-function-return-type
const loadFeatures = async () => await import('./features.js').then((res) => res.default)

interface Props {
  children: ReactNode
}

export default function MotionLazyContainer ({ children }: Props): JSX.Element {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
    </LazyMotion>
  )
}
