/* istanbul ignore file */

import { ReactElement } from 'react'

export interface ModuleConfig {
  key: string
  title: string
  icon?: ReactElement
  conditions?: SingleCondition[] | CompositeCondition[]
}

export enum Condition {
  hasPermissionScope = 'hasPermissionScope',
  isAdmin = 'isAdmin'
}

export interface SingleCondition {
  condition: Condition
  params?: {
    value: string
    [key: string]: string
  }
}

export interface CompositeCondition {
  'or'?: SingleCondition[]
  'and'?: SingleCondition[]
  'not'?: SingleCondition[]
}
