import React from 'react'
import { DialogOptions } from '../../types/dialog/dialog-options'
import { useSetRecoilState } from 'recoil'
import { dialogState } from '../../recoil/dialog'

export interface DialogManager {
  open: (options: DialogOptions) => void
  close: () => void
}

const useDialog = (): DialogManager => {
  const setDialog = useSetRecoilState<DialogOptions | undefined>(dialogState)

  const open = (options: DialogOptions): void => {
    setDialog(options)
  }

  const close = (): void => {
    setDialog(undefined)
  }

  return {
    open,
    close
  }
}

export default useDialog
