export const TextHeight: {[key: string]: number} = {
  h1: 32,
  h2: 28,
  h3: 24,
  h4: 20,
  h5: 16,
  h6: 16,
  p: 16,
  span: 16
}

export const AvatarHeight: {[key: string]: number} = {
  small: 24,
  medium: 32,
  large: 40,
  xlarge: 96,
  xxlarge: 128
}
