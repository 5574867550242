import React, { useEffect, useState } from 'react'
import useBusinessUnit from '../../../common/hooks/use-business-units/useBusinessUnit'
import { Page } from '../../../common/components/pages/page'
import Avatar from '@atlaskit/avatar'
import Spinner from '@atlaskit/spinner'
import apiFetch from '../../../common/utils/apiFetch'
import Button from '@atlaskit/button'
import MoreIcon from '@atlaskit/icon/glyph/more'
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup
} from '@atlaskit/dropdown-menu'
import useSchema from '../../../common/hooks/use-schema/useSchema'
import {
  BusinessUnitEntity
} from 'common/types/odino/built-in/entities/business-unit-entity'
import { EntityEditor } from '../../crm/components/entity-editor/entity-editor'
import { VeiveUser } from 'common/types/current-user'

export const SettingsDetails = () => {
  const [businessUnit, setBusinessUnit] = useBusinessUnit()
  const [users, setUsers] = useState<VeiveUser[]>([])
  const [schema, isSchemaLoading] = useSchema('business-unit')

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const response = await apiFetch('users')
    setUsers(response.users)
  }

  const breadcrumbs = [{
    text: 'Business Units',
    path: '/business-units'
  },
  {
    text: businessUnit?.op_name ?? '',
    path: `/business-units/${businessUnit?.id as string}`
  },
  {
    text: 'Details',
    path: `/business-units/${businessUnit?.id}/settings/business-units/details`
  }]

  const userIdToOption = (userId: string) => {
    if (users && users.length > 0) {
      const user = users.find(q => q.id === userId)
      if (user) {
        return {
          key: user.id,
          label: user.displayName,
          value: user.id
        }
      }
    }
  }

  return (
    <Page
      title='Details'
      pageBreadcrumbs={businessUnit ? breadcrumbs : undefined}
      isCompact
      css={{ alignItems: 'center', width: '100%' }}
      actions={
        <DropdownMenu
          placement='bottom-end'
          trigger={({ triggerRef, ...props }) => (
            <Button
              {...props}
              appearance='subtle'
              iconBefore={<MoreIcon label='more' />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownItem isDisabled>Delete</DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      }
    >
      {businessUnit && !isSchemaLoading
        ? <div style={{ width: 350, display: 'flex', flexDirection: 'column' }}>
          <div style={{ alignSelf: 'center' }}>
            <Avatar size='xxlarge' appearance='square' src={businessUnit.op_avatarUrl as string} />
          </div>
          <div style={{ alignItems: 'flex-start', marginTop: 16 }}>
            <EntityEditor
              entity={businessUnit}
              entityType='business-unit'
              propertiesToEdit={['op_avatarUrl', 'op_name', 'op_businessType', 'op_description']}
              onSave={(entity) => setBusinessUnit(entity as BusinessUnitEntity)}
            />
          </div>
        </div>
        : <Spinner />}
    </Page>
  )
}
