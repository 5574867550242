import React, { ReactElement, useEffect, useState } from 'react'
import { Entity } from 'common/types/odino/entity'
import useSchema from '../../../../common/hooks/use-schema/useSchema'
import { PropertyField } from '../property-field/property-field'
import LoadingGuard from '../../../../common/components/guards/loading-guard'
import { LoadingButton, ButtonGroup } from '@atlaskit/button'
import apiFetch from '../../../../common/utils/apiFetch'
import useEntityCache from '../../../../common/hooks/use-cache/useEntityCache'
import Form, { Field, FormSection, FormFooter } from '@atlaskit/form'
import { validateField, convertToUpdateRequestFormat } from '../property-field/field-utils'
import { ModalBody, ModalFooter } from '@atlaskit/modal-dialog'
import { ConditionalWrapper } from '../conditional-wrapper'

interface EntityEditorProps {
  entity?: Entity
  entityType: string
  hideSaveOption?: boolean
  isForm?: boolean
  isModal?: boolean
  onChange?: (entity: Entity, isValid: boolean) => void
  onSave?: (entity?: Entity) => void
  onCancel?: () => void
  propertiesToEdit?: string[]
  testId?: string
}

export const EntityEditor = (props: EntityEditorProps) => {
  const [newEntity, setNewEntity] = useState({ ...props.entity })
  const [schema, isSchemaLoading] = useSchema(props.entityType)
  const cache = useEntityCache(props.entityType)

  useEffect(() => {
    props.onChange?.(newEntity, true)
  }, [newEntity])

  const updateEntity = (value: any): void => {
    setNewEntity({ ...newEntity, ...value })
  }

  const getPropertyIds = (): string[] => {
    if (props.isForm) {
      return Object.values(schema.properties ?? {}).filter(q => q.id && q.veiveShowOnForms).map(q => q.id as string)
    }
    if (props.propertiesToEdit) {
      const ids: string[] = []
      props.propertiesToEdit.forEach(p => {
        if (schema?.properties && schema.properties[p]) {
          ids.push(p)
        }
      })
      return ids
    }
    return Object.keys(schema.properties ?? {})
  }

  const handleSave = async () => {
    try {
      // Case 1: Existing entity
      if (props.entity?.id) {
        delete newEntity.summary
        delete newEntity.id
        const response = await apiFetch(`graph/entities/${props.entityType}/${props.entity?.id}`, {
          method: 'PUT',
          body: JSON.stringify(convertToUpdateRequestFormat(newEntity))
        })
        if (response?.id) {
          cache.setEntity(response)
          props.onSave?.(response)
        }
      } else {
        // Case 2: New entity
        const response = await apiFetch(`graph/entities/${props.entityType}`, {
          method: 'POST',
          body: JSON.stringify(convertToUpdateRequestFormat(newEntity))
        })
        if (response?.id) {
          cache.setEntity(response)
          props.onSave?.(response)
        }
      }
    } catch (e) {
      console.log(e)
      props.onSave?.(undefined)
    }
  }

  return (
    <LoadingGuard isLoading={isSchemaLoading}>
      <Form<any>
        onSubmit={async (data) => {
          // console.log('form data', data)
          await handleSave()
        }}
      >
        {({ formProps, submitting }) => {
          const FormFooterControl = (): ReactElement => (
            <ButtonGroup>
              {
                props.onCancel &&
                  <LoadingButton
                    appearance='subtle'
                    onClick={() => props.onCancel?.()}
                  >Cancel
                  </LoadingButton>
              }
              <LoadingButton
                type='submit'
                appearance='primary'
                isLoading={submitting}
                testId={`${props.testId}--save`}
              >
                {props.entity?.id ? 'Save' : 'Create'}
              </LoadingButton>
            </ButtonGroup>
          )

          return (
            <form {...formProps} style={{ marginTop: 0 }}>
              <ConditionalWrapper
                condition={props.isModal} wrapper={children => (
                  <ModalBody>
                    {children}
                  </ModalBody>
                )}
              >
                <div>
                  {
                    getPropertyIds().map(fieldId => (
                      <Field
                        key={fieldId}
                        aria-required
                        name={schema.properties?.[fieldId]?.id as string}
                        label={schema.properties?.[fieldId]?.name}
                        isRequired={schema.properties?.[fieldId]?.required}
                        validate={(value) => validateField(schema.properties?.[fieldId], value ?? newEntity[fieldId])}
                      >
                        {({ fieldProps, error, valid, meta }) => {
                          // console.log(fieldProps, error, valid)
                          return (
                            <>
                              <PropertyField
                                {...fieldProps}
                                hideLabel
                                defaultValue={newEntity[fieldId]}
                                value={newEntity[fieldId]}
                                property={schema.properties[fieldId]}
                                onChange={(value: any) => {
                                  updateEntity({ [fieldId]: value })
                                  fieldProps.onChange(value)
                                }}
                                testId={`${props.testId}--${fieldId}`}
                                error={error}
                              />
                            </>
                          )
                        }}
                      </Field>
                    ))
                  }
                </div>
              </ConditionalWrapper>
              {
                props.isModal
                  ? (
                    <ModalFooter>
                      <FormFooterControl />
                    </ModalFooter>
                    )
                  : <FormFooter>
                    <FormFooterControl />
                  </FormFooter>
              }
            </form>
          )
        }}
      </Form>
    </LoadingGuard>
  )
}
