import useAuth from '../use-auth/useAuth'
import { NewScopes } from '../../types/scopes'

export interface PermissionManager {
  hasScope: (scopeId: string) => boolean
  hasModuleAccess: (moduleId: string) => boolean
  isAdmin: () => boolean
}

const usePermissions = (): PermissionManager => {
  const authUser = useAuth()

  return {
    hasScope: scopeId => {
      return authUser.permissions.scopes.includes(scopeId)
    },
    hasModuleAccess: moduleId => {
      return authUser.permissions.modules.includes(moduleId)
    },
    isAdmin: () => { return authUser.permissions.scopes.includes(NewScopes.veiveAdmin) }
  }
}

export default usePermissions
