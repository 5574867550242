import React, { ReactElement, useState } from 'react'
import InlineEdit from '@atlaskit/inline-edit'
import {
  fontSize as getFontSize,
  gridSize as getGridSize
} from '@atlaskit/theme/constants'
import { Entity } from 'common/types/odino/entity'
import { PropertiesSchema } from '../../../../common/hooks/use-schema/useSchema'
import { PropertyRenderer } from '../renderers/property-renderer'
import { PropertyField } from '../property-field/property-field'
import { validateField, convertToUpdateRequestFormat } from '../property-field/field-utils'

interface PropertyGroupEditorProps {
  entity?: Entity
  group: {id: string, props: string[]}
  onPropertyChange?: (property: {id: string, value: any}) => void
  schema: PropertiesSchema
}

export const PropertyGroupEditor = ({ entity, group, onPropertyChange, schema }: PropertyGroupEditorProps): ReactElement | null => {
  const [localEntity, setLocalEntity] = useState<Entity | undefined>(entity)
  const fontSize = getFontSize()
  const gridSize = getGridSize()
  const readViewContainerStyles = {
    display: 'flex',
    maxWidth: '100%',
    overflow: 'hidden',
    minHeight: `${(gridSize * 2) / fontSize}em`,
    padding: `${gridSize - 2}px ${gridSize - 2}px`,
    fontSize: `${fontSize}px`,
    lineHeight: `${(gridSize * 2) / fontSize}`
  }

  const updateEntity = (property: {id: string, value: any}): void => {
    setLocalEntity({ ...localEntity, ...{ [property.id]: property.value } })
    onPropertyChange?.(property)
  }

  const transformEmptyValue = (value: any): any => {
    if (typeof value === 'string' && String.length === 0) {
      return null
    }
    if (typeof value === 'number') {
      if (Number.isNaN(value) || !Number.isFinite(value)) {
        return null
      }
    }
    return value
  }

  if (!localEntity) {
    return null
  }
  return (
    <div>
      <h6 style={{ marginBottom: 16, padding: '6px 0', borderBottom: '1px solid #091E4224' }}>{schema?.groups?.[group.id]?.name}</h6>
      {
        group.props.map((q, index) => {
          if (!schema?.properties?.[q]) {
            return null
          }
          return (
            <div key={`${q}-${index}`}>
              {
                schema.properties[q].readOnlyValue
                  ? <div style={{ marginTop: 8 }}>
                    <label>{schema.properties[q]?.name}</label>
                    <div style={readViewContainerStyles} data-testid='read-only-view'>
                      <PropertyRenderer
                        schema={schema.properties[q]}
                        value={localEntity[q]}
                      />
                    </div>
                  </div>

                  : <InlineEdit
                      editView={({ errorMessage, ...fieldProps }) => (
                        <PropertyField
                          {...fieldProps}
                          autoFocus
                          hideLabel
                          isCompact
                          error={errorMessage}
                          property={schema.properties[q]}
                        />
                      )}
                      label={schema.properties[q]?.name}
                      isRequired={schema.properties[q]?.required}
                      onConfirm={(value) => {
                        // console.log('confirm', value)
                        updateEntity({ id: schema.properties[q].id as string, value: transformEmptyValue(value) })
                      }}
                      readView={() => (
                        <div style={readViewContainerStyles} data-testid='read-view'>
                          {localEntity[q]
                            ? <PropertyRenderer
                                schema={schema.properties[q]}
                                value={localEntity[q]}
                              />
                            : <span>-</span>}
                        </div>
                      )}
                      defaultValue={localEntity[q]}
                      readViewFitContainerWidth
                      validate={value => validateField(schema.properties[q], value)}
                    />
              }

            </div>
          )
        })
      }
    </div>
  )
}
