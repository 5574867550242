import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import './index.css'
import 'react-loading-skeleton/dist/skeleton.css'
import '@atlaskit/css-reset'
import reportWebVitals from './reportWebVitals'
import ScrollToTop from './common/components/scroll-to-top'
import Router from './routes'
import { RecoilRoot } from 'recoil'
import MotionLazyContainer from './common/components/animate/motion-lazy-container'
import { FlagsProvider } from '@atlaskit/flag'

// const root = ReactDOM.createRoot(document.getElementById('root'))
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <RecoilRoot>
          <MotionLazyContainer>
            <ScrollToTop />
            <FlagsProvider>
              <Router />
            </FlagsProvider>
          </MotionLazyContainer>
        </RecoilRoot>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
