import React from 'react'
import DropdownMenu, {
  DropdownMenuProps
} from '@atlaskit/dropdown-menu'
import Button, { Appearance } from '@atlaskit/button'
import MoreIcon from '@atlaskit/icon/glyph/more'

interface MoreDropdownMenuProps extends DropdownMenuProps {
  buttonAppearance?: Appearance
}

export const MoreDropdownMenu = (props: MoreDropdownMenuProps): JSX.Element => {
  return (
    <DropdownMenu
      placement='bottom-end'
      trigger={({ triggerRef, ...tprops }) => (
        <Button
          {...tprops}
          appearance={props.buttonAppearance ?? 'default'}
          iconBefore={<MoreIcon label='more' />}
          ref={triggerRef}
        />
      )}
      {...props}
    >
      {props.children}
    </DropdownMenu>
  )
}
