import React, { ChangeEvent, ReactElement } from 'react'
import Textfield from '@atlaskit/textfield'
import TextArea from '@atlaskit/textarea'
import { DateTimePicker, DatePicker, TimePicker } from '@atlaskit/datetime-picker'
import Select, { CheckboxSelect } from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'
import {
  PropertyType,
  PropertyFieldType
} from 'common/types/crm/property-schema'
import useLocale from '../../../../common/hooks/use-locale/use-locale'
import {
  addSecondsToISODateTime,
  addSecondsToISOTime
} from '../../../../common/utils/string-utils'
import { EntityPropertySchema } from 'common/types/odino/entity-schema'
import { ErrorMessage } from '@atlaskit/form'

export interface FieldProps {
  defaultValue?: any
  error?: string
  hideLabel?: boolean
  onChange?: (value: string | number | boolean) => void
  property: EntityPropertySchema
  testId?: string
}

export const PropertyField = (props: FieldProps | any): ReactElement => {
  // console.log('error', props.error)
  const locale = useLocale()
  const getLabel = (): ReactElement | null => {
    if (props.hideLabel) {
      return null
    }
    let label: string = props.property.name
    if (props.property.required) {
      label += ' *'
    }
    return <label>{label}</label>
  }

  const getFieldValue = (value: any): any => {
    if (value && props.property) {
      switch (props.property.veiveFieldType) {
        case PropertyFieldType.selectsingle:
          return {
            key: props.property.id,
            value: value,
            label: value
          }
        default:
          return value
      }
    }
    return undefined
  }

  const getField = (): ReactElement => {
    switch (props.property.veiveFieldType) {
      case PropertyFieldType.text:
      case PropertyFieldType.textnumber:
        return (
          <Textfield
            {...props}
            testId={`${props.testId}--textfield`}
            defaultValue={getFieldValue(props.defaultValue)}
            value={getFieldValue(props.value)}
            type={props.property.type === PropertyType.number ? 'number' : 'string'}
            onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange?.(props.property.type === PropertyType.number ? parseInt(e.target.value) : e.target.value)}
          />
        )
      case PropertyFieldType.textarea:
        return (
          <TextArea
            {...props}
            defaultValue={getFieldValue(props.defaultValue)}
            value={getFieldValue(props.value)}
            testId={`${props.testId}--textarea`}
            onChange={(e: any) => props.onChange?.(e.target.value)}
          />
        )
      case PropertyFieldType.datetime:
        return (
          <DateTimePicker
            {...props}
            locale={locale.code}
            defaultValue={getFieldValue(props.defaultValue)}
            value={getFieldValue(props.value)}
            testId={`${props.testId}--datetime`}
            onChange={date => props.onChange?.(addSecondsToISODateTime(date))}
          />
        )
      case PropertyFieldType.date:
        return (
          <DatePicker
            {...props}
            defaultValue={getFieldValue(props.defaultValue)}
            value={getFieldValue(props.value)}
            locale={locale.code}
            testId={`${props.testId}--date`}
            onChange={date => props.onChange?.(date)}
          />
        )
      case PropertyFieldType.time:
        return (
          <TimePicker
            {...props}
            defaultValue={getFieldValue(props.defaultValue)}
            value={getFieldValue(props.value)}
            locale={locale.code}
            testId={`${props.testId}--time`}
            onChange={time => props.onChange?.(addSecondsToISOTime(time))}
          />
        )
      case PropertyFieldType.selectsingle:
        return (
          <Select
            menuPosition='fixed'
            {...props}
            defaultValue={getFieldValue(props.defaultValue)}
            value={getFieldValue(props.value)}
            testId={`${props.testId}--select`}
            options={props.property.enum?.map((q: string) => ({ key: q, label: q, value: q }))}
            onChange={(e) => props.onChange?.(e?.value ?? '')}
          />
        )
      case PropertyFieldType.selectmulti:
        return (
          <CheckboxSelect
            menuPosition='fixed'
            {...props}
            defaultValue={getFieldValue(props.defaultValue)}
            value={getFieldValue(props.value)}
            testId={`${props.testId}--multiselect`}
            options={props.property.items?.enum?.map((q: string) => ({ key: q, label: q, value: q }))}
            onChange={(e) => props.onChange?.(e.map((q: any) => q.value))}
          />
        )
      case PropertyFieldType.toggle:
        return (
          <div>
            <Toggle
              {...props}
              isChecked={props.value}
              defaultValue={getFieldValue(props.defaultValue)}
              value={getFieldValue(props.value)}
              testId={`${props.testId}--toggle`}
              onChange={(e) => props.onChange?.(e.target.checked)}
            />
          </div>
        )
      default:
        return <p>Unsupported Field</p>
    }
  }

  return (
    <>
      {getLabel()}
      {getField()}
      {
        props.error &&
          <ErrorMessage>{props.error}</ErrorMessage>
      }
    </>
  )
}
