import React, { ReactElement, useState } from 'react'
import {
  AppSwitcher,
  AtlassianNavigation,
  Create,
  IconButton, PrimaryButton, PrimaryDropdownButton
} from '@atlaskit/atlassian-navigation'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import Avatar from '@atlaskit/avatar'
import useAuth from '../../common/hooks/use-auth/useAuth'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ElementPrimaryAction,
  NavigationPrimaryAction
} from '../../common/types/app-config/navigation-config'
import { Popup } from '@atlaskit/popup'
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu'
import useCondition from '../../common/hooks/use-condition/useCondition'
import { AppConfigDescriptor } from '../../common/types/app-config'
import AdminGuard from '../../common/components/guards/admin-guard'
import PageGuard from '../../common/components/guards/page-guard'

export const VeiveTopNavigation = ({ apps, testId }: {apps: AppConfigDescriptor[], testId?: string}): JSX.Element => {
  const [dropOpen, setDropOpen] = useState<{[key: string]: boolean}>({})
  const user = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const condition = useCondition()

  const togglePopup = (key: string, isOpen: boolean): void => {
    const newState = { ...dropOpen }
    newState[key] = isOpen
    setDropOpen(newState)
  }

  const buildTopNavigationPrimaryItems = () => {
    const items: ReactElement[] = [
      <PrimaryButton
        key='pri-home'
        onClick={() => navigate('/')}
        isHighlighted={location.pathname === '/'}
      >
        Home
      </PrimaryButton>
    ]
    for (const app of apps) {
      if (app.modules?.navigation) {
        if (app.modules.navigation?.primaryActions && app.modules.navigation.primaryActions.length > 0) {
          const primaryNavElements = []
          const primaryNavLinks = [] as NavigationPrimaryAction[]
          for (const navConfig of app.modules?.navigation?.primaryActions) {
            if (navConfig.conditions && !condition.evaluate(navConfig.conditions)) {
              continue
            }
            const elemAction = navConfig as ElementPrimaryAction
            if (elemAction.element && React.isValidElement(elemAction.element)) {
              primaryNavElements.push(elemAction.element)
            } else {
              primaryNavLinks.push(navConfig as NavigationPrimaryAction)
            }
          }

          if (primaryNavElements.length > 0) {
            items.push(...primaryNavElements)
          }

          if (primaryNavLinks.length > 0) {
            items.push(
              <Popup
                placement='bottom-start'
                trigger={
                  (triggerProps) => (
                    <PrimaryDropdownButton
                      {...triggerProps}
                      isSelected={dropOpen[app.key]}
                      isHighlighted={location.pathname.startsWith(app.basePath)}
                      onClick={() => togglePopup(app.key, !dropOpen[app.key])}
                    >
                      {app.name}
                    </PrimaryDropdownButton>
                  )
                }
                isOpen={dropOpen[app.key]}
                onClose={() => togglePopup(app.key, false)}
                content={() => <MenuGroup>
                  <Section>
                    {primaryNavLinks.map(q => (
                      <ButtonItem
                        key={q.key}
                        iconBefore={q.icon}
                        description={q.description}
                        onClick={() => {
                          navigate(`${app.basePath}${q.path}`)
                          togglePopup(app.key, false)
                        }}
                      >
                        {q.title}
                      </ButtonItem>
                    ))}
                  </Section>
                </MenuGroup>}
              />
            )
          }
        }
        // TODO: Add single links
      } else if (app.modules?.generalPages) {
        for (const gPage of app.modules?.generalPages) {
          const fullPath = `${app.basePath}${gPage.path}`
          const link = (
            <PageGuard config={gPage}>
              <PrimaryButton
                key={`pri-${app.key}-${gPage.key}`}
                isHighlighted={location.pathname.startsWith(fullPath)}
                onClick={() => navigate(fullPath)}
              >
                {gPage.title}
              </PrimaryButton>
            </PageGuard>
          )
          if (link) { items.push(link) }
        }
      }
    }
    items.push(
      <PrimaryButton
        key='pri-apps'
        isHighlighted={location.pathname.startsWith('/apps')}
      >
        Apps
      </PrimaryButton>
    )
    return items
  }

  return (
    <AtlassianNavigation
      renderAppSwitcher={() => <AppSwitcher tooltip='Switch to...' />}
      renderProductHome={() =>
        <div style={{ display: 'flex', alignItems: 'center', margin: '0', cursor: 'pointer', marginLeft: 5, marginRight: 20 }}>
          <img alt='veive logo' src='/logo.svg' style={{ height: 22 }} />
          <h3 style={{ margin: 0, marginLeft: 5, fontSize: 18 }}>Veive</h3>
        </div>}
      label='Veive CRM'
      primaryItems={buildTopNavigationPrimaryItems()}
      renderCreate={() =>
        <Create
          buttonTooltip='Create new customer data'
          iconButtonTooltip='Create new customer data'
          text='Create'
          href='#'
        />}
      renderSettings={
        () =>
          <AdminGuard>
            <IconButton testId={testId ? `${testId}-settings-btn` : undefined} tooltip='Change Settings' icon={<SettingsIcon label='Settings' />} onClick={() => navigate('/admin/settings')} />
          </AdminGuard>
      }
      renderProfile={() =>
        <Avatar
          testId={testId ? `${testId}-avatar` : undefined}
          size='small'
          src={user.avatar}
          onClick={() => console.log}
        />}
    />
  )
}
