import { useRecoilValue } from 'recoil'
import { AuthUser, authUserState } from '../../recoil/user'

// ----------------------------------------------------------------------

const useAuth = (): AuthUser => {
  return useRecoilValue(authUserState)
}

export default useAuth
