import React, { ReactElement, ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import PageHeader from '@atlaskit/page-header'
import { PageHeaderProps } from '@atlaskit/page-header/dist/types/PageHeader'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import { useNavigate } from 'react-router-dom'
import Spinner from '@atlaskit/spinner'

export interface AtlaskitBreadcrumbsProps{
  path: string
  text: string
}

export interface AtlaskitPageProps extends PageHeaderProps{
  title?: string
  children: ReactNode
  meta?: ReactNode
  metaTitle?: string
  isCentered?: boolean
  isCompact?: boolean
  css?: any
  pageBreadcrumbs?: AtlaskitBreadcrumbsProps[] | undefined
  isLoading?: boolean
}

export const PageBreadcrumbs = ({ pageBreadcrumbs }: {pageBreadcrumbs?: AtlaskitBreadcrumbsProps[]}): JSX.Element | null => {
  const navigate = useNavigate()
  if (!pageBreadcrumbs) { return null }
  return (
    <Breadcrumbs>
      {
        pageBreadcrumbs.map(q => <BreadcrumbsItem key={q.path} onClick={() => navigate(q.path)} text={q.text} />)
      }
    </Breadcrumbs>
  )
}

export const Page = (props: AtlaskitPageProps): ReactElement => {
  let css = { marginTop: 24, display: 'flex', flexDirection: 'column', paddingBottom: 60 }
  let rootCss: React.CSSProperties = { display: 'flex', flexDirection: 'column', height: '100%' }
  if (props.isCentered || props.isLoading) {
    css = { ...css, ...{ alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 160px)' } }
  }
  if (props.isCompact) {
    rootCss = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flexWrap: 'wrap', margin: '0 auto', maxWidth: 960, position: 'relative' }
  }
  return (
    <div style={rootCss}>
      <Helmet>
        <title>{props.metaTitle ?? props.title}</title>
        {props.meta}
      </Helmet>
      <div style={{ width: '100%' }}>
        <PageHeader
          breadcrumbs={props.breadcrumbs ?? <PageBreadcrumbs pageBreadcrumbs={props.pageBreadcrumbs} />}
          actions={props.actions}
          bottomBar={props.bottomBar}
        >
          {props.title}
        </PageHeader>
      </div>

      <div style={{ ...css, ...props.css }}>
        {props.isLoading ? <Spinner size='medium' /> : props.children}
      </div>
    </div>
  )
}
