import React, { ReactElement } from 'react'
import Avatar, { AvatarPropTypes, Skeleton as AvatarSkeleton } from '@atlaskit/avatar'
import { SkeletonItem } from '@atlaskit/menu'
import { TextHeight, AvatarHeight } from '../../types/sizes'

interface FullAvatarProps extends AvatarPropTypes {
  avatarUrl?: string
  isLoading?: boolean
  label?: string
  labelSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
}

const FullAvatarSkeleton = (props: any): ReactElement => {
  const labelSize = props.labelSize ?? 'span'
  return (
    <SkeletonItem
      isShimmering
      hasIcon={props.appearance === 'square'}
      hasAvatar={props.appearance === 'circle'}
      cssFn={(style) => ({
        padding: 0,
        '&&::after': { height: (TextHeight[labelSize] ?? TextHeight.span) - 4 },
        '&&::before': { height: AvatarHeight[props.size] ?? AvatarHeight.medium, width: AvatarHeight[props.size] ?? AvatarHeight.medium }
      })}
    />
  )
}

export const FullAvatar = (props: FullAvatarProps): ReactElement => {
  const labelSize = props.labelSize ?? 'span'
  if (props.isLoading) {
    return <FullAvatarSkeleton {...props} />
  }
  const element = React.createElement(labelSize, { style: { marginTop: 0, marginLeft: 8 } }, props.label)
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar src={props.avatarUrl} {...props} />
      {element}
    </div>
  )
}
