import {
  CompositeCondition,
  Condition,
  SingleCondition
} from '../../types/app-config/module-config'
import { NewScopes } from '../../types/scopes'
import usePermissions from '../use-permissions/usePermissions'

export interface ConditionManager {
  evaluate: (conditions: SingleCondition[] | CompositeCondition[]) => boolean
}

const useCondition = (): ConditionManager => {
  const permissions = usePermissions()

  // ----------------------------------------------------------------------
  // EVALUATE FUNCTIONS
  type EvaluateFunction = (condition: SingleCondition) => boolean

  const evaluateScope: EvaluateFunction = (condition) => {
    return condition.params?.value !== undefined && permissions.hasScope(condition.params.value)
  }

  const evaluateIfUserIsAdmin: EvaluateFunction = (condition) => {
    return permissions.hasScope(NewScopes.veiveAdmin)
  }

  // ----------------------------------------------------------------------
  // HOOK
  const isCompositeConditionArray = (conditions: any[]) => {
    return !!(conditions[0].or || conditions[0].and || conditions[0].not)
  }

  const evaluateConditions = (conditions: SingleCondition[] | CompositeCondition[]): boolean => {
    if (conditions.length === 0) {
      return true
    }
    if (isCompositeConditionArray(conditions)) {
      // TODO Check Composite conditions
      throw new Error('Not yet supported')
    } else {
      return (conditions as SingleCondition[]).every(q => evaluateMap[q.condition]?.(q))
    }
  }

  const evaluateMap: { [key in Condition]?: EvaluateFunction } = {
    [Condition.hasPermissionScope]: evaluateScope,
    [Condition.isAdmin]: evaluateIfUserIsAdmin
  }

  return { evaluate: evaluateConditions }
}

export default useCondition
