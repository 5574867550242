import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import apiFetch from '../../utils/apiFetch'
import {
  EntityGroup,
  EntityPropertySchema
} from 'common/types/odino/entity-schema'
import { schemaState } from '../../recoil/schema'

export interface PropertiesSchema {
  properties: {[key: string]: EntityPropertySchema}
  groups: {[key: string]: EntityGroup}
}

const useSchema = (entityType: string): [PropertiesSchema, boolean] => {
  const [schemas, setSchemas] = useRecoilState(schemaState)
  const [isLoading, setIsLoading] = React.useState(false)

  useEffect(() => {
    updateSchema()
  }, [])

  const updateSchema = async (): Promise<void> => {
    if (!schemas[entityType]) {
      setIsLoading(true)
      const newSchemas = { ...schemas }
      const response = await apiFetch<PropertiesSchema>(`graph/schema/entities/${entityType}`)
      newSchemas[entityType] = response
      setSchemas(newSchemas)
      setIsLoading(false)
    }
  }

  return [schemas[entityType] ?? {}, isLoading]
}

export default useSchema
