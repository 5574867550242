import React from 'react'
import {
  FormattedProperty,
  PropertyRenderType,
  PropertyType
} from 'common/types/crm/property-schema'
import useLocale from '../../../../common/hooks/use-locale/use-locale'
import { EntityPropertySchema } from 'common/types/odino/entity-schema'
import { SummaryRenderer } from './summary-renderer'

interface PropertyElementProps {
  onClick?: (data: any) => void
  schema?: EntityPropertySchema
  testId?: string
  value?: any
}

export const PropertyRenderer = ({ onClick, schema, testId, value }: PropertyElementProps): React.ReactElement | null => {
  const locale = useLocale()
  if (!schema || !schema?.veiveRenderType) {
    return null
  }

  if (!value) {
    return (<span>-</span>)
  }
  switch (schema.veiveRenderType) {
    case PropertyRenderType.datetime:
      return (
        <span>
          {`${locale.provider.formatDate(new Date(value))} - ${locale.provider.formatTime(new Date(value))}`}
        </span>
      )
    case PropertyRenderType.date:
      return (
        <span>
          {locale.provider.formatDate(new Date(value))}
        </span>
      )
    case PropertyRenderType.time:
      return (
        <span>
          {locale.provider.formatTime(new Date(`2000-01-01T${value}`))}
        </span>
      )
    case PropertyRenderType.email:
      return (
        <a data-testid={testId ? `${testId}--email` : undefined} href={`mailto:${value}`}>{value}</a>
      )
    case PropertyRenderType.phone:
      return (
        <a data-testid={testId ? `${testId}--phone` : undefined} href={`tel:${value}`}>{value}</a>
      )
    case PropertyRenderType.relation:
      if (value && value.length > 0) {
        return (
          <SummaryRenderer summary={value[0]} onClick={() => onClick?.(value[0])} />
        )
      }
      return null
    case PropertyRenderType.text:
    default:
      if (schema.type === PropertyType.array) {
        return (
          <span data-testid={testId}>
            {value.toString()}
          </span>
        )
      }
      return (
        <span data-testid={testId}>
          {value.toString()}
        </span>
      )
  }
}
