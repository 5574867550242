import React from 'react'
import { Page } from '../common/components/pages/page'

export default function ShellApp (): JSX.Element {
  return (
    <Page title='Shell'>
      <p>SHELL</p>
    </Page>
  )
}
