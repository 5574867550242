import React, { ReactElement, useEffect, useState } from 'react'
import { Entity, EntitySummary } from 'common/types/odino/entity'
import Button, { ButtonGroup } from '@atlaskit/button'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import Tooltip from '@atlaskit/tooltip'
import MoreIcon from '@atlaskit/icon/glyph/more'
import { SummaryRenderer } from '../renderers/summary-renderer'
import { View } from 'common/types/odino/view'
import useSchema from '../../../../common/hooks/use-schema/useSchema'
import { PropertyGroupEditor } from './property-group-editor'
import { PropertyGroupSkeleton } from './property-group-skeleton'

export interface EntityPanelProps {
  entity?: Entity
  summary?: EntitySummary
  view?: View
  isLoading?: boolean
  onCloseClick?: () => void
  onEntityChange?: (entity?: Entity) => void
}

export const EntityPanel = ({ entity, view, isLoading, onCloseClick, onEntityChange, summary }: EntityPanelProps): ReactElement => {
  const [currentEntity, setCurrentEntity] = useState<Entity | undefined>(entity)
  const [schema, isSchemaLoading] = useSchema(summary?.type ?? entity?.type as string ?? '')

  const getAvailableGroupIds = (): string[] => {
    return Object.keys(view?.groups ?? {})
  }

  useEffect(() => {
    onEntityChange?.(currentEntity)
  }, [currentEntity])

  const updateEntity = (value: any): void => {
    setCurrentEntity({ ...currentEntity, ...value })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: 48 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1 }}>
          <SummaryRenderer
            isLoading={isLoading}
            labelSize='h4'
            size='medium'
            summary={currentEntity?.summary ?? summary}
          />
        </div>
        <ButtonGroup>
          <Tooltip position='top' content='Close'>
            <Button
              iconAfter={<CrossIcon label='close' />}
              appearance='subtle'
              onClick={() => onCloseClick?.()}
            />
          </Tooltip>
        </ButtonGroup>
      </div>
      <div style={{ flex: 1, overflowY: 'scroll', marginTop: 24, paddingBottom: 48 }}>
        {
          isLoading
            ? <div style={{ paddingRight: 8 }}> <PropertyGroupSkeleton /></div>
            : getAvailableGroupIds().map(gId => (
              <div style={{ marginBottom: 24, paddingRight: 8 }} key={gId}>
                <PropertyGroupEditor
                  entity={entity}
                  group={{ id: gId, props: view?.groups?.[gId] ?? [] }}
                  onPropertyChange={prop => updateEntity({ [prop.id]: prop.value })}
                  schema={schema}
                />
              </div>
            ))
        }
      </div>
    </div>
  )
}
