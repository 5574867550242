import React, { ReactElement, useState } from 'react'
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu'
import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation'
import { Popup } from '@atlaskit/popup'
import Avatar from '@atlaskit/avatar'
import { useNavigate, useLocation } from 'react-router-dom'
import useEntityCache from '../../../common/hooks/use-cache/useEntityCache'
import useDialog from '../../../common/hooks/use-dialog/useDialog'

export const NavigationBusinessUnits = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const cache = useEntityCache('business-unit', true)
  const dialog = useDialog()

  const openCreateDialog = (): void => {
    dialog.open({
      key: 'veive-crm-dialog-create-entity',
      input: {
        title: 'Create Business Unit',
        entityType: 'business-unit'
      },
      onClose: ({ entity }) => {
        navigate(`/business-units/${entity.id}`)
      }
    })
  }

  return (
    <Popup
      placement='bottom-start'
      trigger={
      (triggerProps) => (
        <PrimaryDropdownButton
          {...triggerProps}
          isSelected={isOpen}
          isHighlighted={location.pathname.startsWith('/business-units')}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          Business Units
        </PrimaryDropdownButton>
      )
    }
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={() => <MenuGroup>
        <Section title='Recent'>
          {cache.getEntities().slice(0, 4).map(q => (
            <ButtonItem
              key={q.id}
              description={q.op_businessType as string}
              iconBefore={<Avatar size='small' appearance='square' src={q.op_avatarUrl as string} />}
              onClick={() => {
                navigate(`/business-units/${q.id}`)
                setIsOpen(false)
              }}
            >
              <p style={{ width: 220 }}>{q.op_name as string}</p>
            </ButtonItem>
          ))}
        </Section>
        <Section hasSeparator>
          <ButtonItem onClick={() => navigate('/business-units')}>
            View all business units
          </ButtonItem>
          <ButtonItem onClick={() => openCreateDialog()}>
            Create business unit
          </ButtonItem>
        </Section>
      </MenuGroup>}
    />
  )
}
