export default async function apiFetch<T> (endpoint: string, init?: RequestInit | Request): Promise<any> {
  const res = await fetch(`/api/${endpoint}`, init)
  if (res.status === 200) {
    return await res.json<T>()
  }
  if (res.status === 401 || res.status === 403) {
    window.location.href = '/403'
    return
  }
  throw new Error('Unexpected Error')
}
