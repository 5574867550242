import { EntityPropertySchema } from 'common/types/odino/entity-schema'
import Ajv from 'ajv'
import addFormats from 'ajv-formats'

export const validateField = (schema: EntityPropertySchema, value: any): string | undefined => {
  const ajv = new Ajv({ strictSchema: false })
  addFormats(ajv)
  const jsonSchema = { ...schema }
  delete jsonSchema.required
  delete jsonSchema.id
  const validator = ajv.compile(jsonSchema)

  if (!schema) {
    return undefined
  }
  // console.log(schema)
  if (schema.required && !value) {
    return 'This field is required'
  }
  if (!value) {
    return undefined
  }
  const result = validator(value)
  if (!result && validator.errors) {
    // console.log(validator.errors)
    if (validator.errors[0].keyword === 'format' || validator.errors[0].keyword === 'pattern') {
      return 'Invalid format'
    }
    return `Field ${validator.errors[0]?.message}`
  }
  return undefined
}

export const convertToUpdateRequestFormat = (obj: any): any => {
  const newObj = { ...obj }
  delete newObj.id
  delete newObj.type
  delete newObj.summary
  delete newObj.op_createdAt
  delete newObj.op_updatedAt
  return { properties: newObj }
}
