import React, { ReactElement } from 'react'
import { EntitySummary } from 'common/types/odino/entity'
import { FullAvatar } from '../../../../common/components/avatar/full-avatar'
import { ConditionalWrapper } from '../conditional-wrapper'
import { SizeType } from '@atlaskit/avatar'

interface SummaryRendererProps {
  isLoading?: boolean
  labelSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
  onClick?: () => void
  size?: SizeType
  summary?: EntitySummary
  testId?: string
}

export const SummaryRenderer = (props: SummaryRendererProps): ReactElement | null => {
  if (!props.summary && !props.isLoading) {
    return null
  }
  return (
    <ConditionalWrapper
      condition={props.onClick !== undefined}
      wrapper={children => (
        <a
          style={{ cursor: 'pointer' }}
          data-testid={props.testId}
          onClick={() => props.onClick?.()}
        >
          {children}
        </a>
      )}
    >
      <FullAvatar
        size={props.size ?? 'small'}
        avatarUrl={props.summary?.avatarUrl}
        appearance={props.summary?.type === 'contact' ? 'circle' : 'square'}
        label={props.summary?.title}
        labelSize={props.labelSize}
        isLoading={props.isLoading}
        testId={props.testId ? `${props.testId}--avatar` : undefined}
      />
    </ConditionalWrapper>
  )
}
