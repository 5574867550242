export function capitalizeFirstLetter (str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function escapePathForRegex (str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function addSecondsToISODateTime (isoTime: string): string {
  return isoTime.split('+').join(':00+')
}

export function addSecondsToISOTime (isoTime: string): string {
  return isoTime + ':00'
}
