import React, { ChangeEvent, useEffect, useState } from 'react'
import { Page } from '../../../common/components/pages/page'
import Button, { LoadingButton } from '@atlaskit/button'
import EmptyState from '@atlaskit/empty-state'
import DynamicTable from '@atlaskit/dynamic-table'
import { Role } from 'common/types/role'
import apiFetch from '../../../common/utils/apiFetch'
import { ModalDialog } from '../../../common/components/modal/modal-dialog'
import Select, { CheckboxSelect } from '@atlaskit/select'
import { Scope } from 'common/types/odino/entity-schema'
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup
} from '@atlaskit/dropdown-menu'
import MoreIcon from '@atlaskit/icon/glyph/more'
import Textfield from '@atlaskit/textfield'

export const AdminPageRoles = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [roles, setRoles] = useState<Role[]>([])
  const [scopes, setScopes] = useState<Scope[]>([])
  const [selectedRole, setSelectedRole] = useState<Role | undefined>()
  const scopeKeys: {[key: string]: Scope} = {}
  for (const scope of scopes) {
    scopeKeys[scope.id] = scope
  }

  useEffect(() => {
    getRoles()
    getScopes()
  }, [])

  useEffect(() => {
    if (!dialogOpen) {
      getRoles()
    }
  }, [dialogOpen])

  const getRoles = async () => {
    const roleResponse = await apiFetch('roles')
    setRoles(roleResponse.roles)
  }

  const getScopes = async () => {
    const scopeResponse = await apiFetch('roles/scopes')
    setScopes(scopeResponse.scopes)
  }

  const openRoleDialog = (role?: Role): void => {
    setSelectedRole(role)
    setDialogOpen(true)
  }

  const EditRoleDialog = (props: {role?: Role}) => {
    const [selectedModules, setSelectedModules] = useState<string[]>(props.role?.modules ?? [])
    const [selectedScopes, setSelectedScopes] = useState<string[]>(props.role?.scopes ?? [])
    const [roleName, setRoleName] = useState<string | undefined>(props.role?.name)
    const [isNew] = useState<boolean>(props.role?.id === undefined)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const modules = ['admin', 'crm']

    const handleSave = async () => {
      setIsSaving(true)
      const newRole: Role = isNew ? {} : { ...props.role }
      newRole.name = roleName
      newRole.modules = selectedModules
      newRole.scopes = selectedScopes
      try {
        if (newRole.id) {
          const resp = await apiFetch(`roles/${newRole.id}`, {
            method: 'PUT',
            body: JSON.stringify(newRole)
          })
        } else {
          // Create new
          await apiFetch('roles', {
            method: 'POST',
            body: JSON.stringify(newRole)
          })
        }
        setDialogOpen(false)
      } catch (e) {
        console.log(e)
      }
      setIsSaving(false)
    }

    const handlePermissionChanges = (newSelection: any[]) => {
      const newScopes = newSelection.map(q => q.value)
      setSelectedScopes(newScopes)
    }

    const handleModulesChanges = (newSelection: any[]) => {
      const newModules = newSelection.map(q => q.value)
      setSelectedModules(newModules)
    }

    const scopeToOption = (scope: Scope) => {
      return {
        key: scope.id,
        label: scope.name,
        value: scope.id
      }
    }

    const moduleToOption = (module: string) => {
      return {
        key: module,
        label: module,
        value: module
      }
    }

    return (
      <ModalDialog
        isOpen={dialogOpen}
        header={props.role ? 'Edit role' : 'New Role'}
        onClose={() => setDialogOpen(false)}
        onSecondaryClick={() => setDialogOpen(false)}
        onPrimaryClick={async () => await handleSave()}
        isPrimaryButtonLoading={isSaving}
        isPrimaryButtonDisabled={!roleName || selectedModules.length === 0 || selectedScopes.length === 0}
      >
        <label>Role name</label>
        <Textfield
          value={roleName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setRoleName(e.target.value)}
        />
        <label style={{ marginTop: 20 }}>Modules</label>
        <Select
          isMulti
          defaultValue={props.role?.modules?.map(q => moduleToOption(q))}
          options={modules.map(q => moduleToOption(q))}
          menuPosition='fixed'
          onChange={(items) => handleModulesChanges(items as any[])}
        />
        <label style={{ marginTop: 20 }}>Scopes</label>
        <CheckboxSelect
          defaultValue={props.role?.scopes?.map(q => scopeToOption(scopeKeys[q]))}
          options={scopes.map(q => scopeToOption(q))}
          menuPosition='fixed'
          onChange={(items) => handlePermissionChanges(items as any[])}
          isMulti
        />
      </ModalDialog>
    )
  }

  return (
    <Page
      title='Roles'
      pageBreadcrumbs={
        [{
          path: '/',
          text: 'Home'
        },
        {
          path: '/admin',
          text: 'Admin'
        },
        {
          path: '/admin/roles',
          text: 'Roles'
        }]
      }
      actions={
        <LoadingButton
          appearance='primary'
          isDisabled={roles.length === 0}
          onClick={() => openRoleDialog()}
        >
          New Role
        </LoadingButton>
      }
    >
      <DynamicTable
        isLoading={roles.length === 0}
        isFixedSize
        head={{
          cells: [
            {
              key: 'role',
              content: 'Role Name',
              isSortable: false
            },
            {
              key: 'modules',
              content: 'Modules'
            },
            {
              key: 'scopes',
              content: 'Scopes'
            },
            {
              key: 'actions',
              content: ''
            }
          ]
        }}
        rows={roles.map((item, index) => ({
          key: `row-${index}`,
          cells: [
            {
              key: `row-${index}-name`,
              content: item.name
            },
            {
              key: `row-${index}-modules`,
              content: item.modules?.join(' ')
            },
            {
              key: `row-${index}-scopes`,
              content: <div>{item.scopes?.map((q, index2) => <span style={{ marginRight: 10 }} key={`row-${index}-scopes-${index2}`}>{scopeKeys[q]?.name}</span>)}</div>,
              shouldTruncate: true
            },
            {
              key: `row-${index}-actions`,
              content: (
                <div
                  style={{ display: 'flex', justifyContent: 'right' }}
                >
                  <DropdownMenu
                    placement='bottom-end'
                    trigger={({ triggerRef, ...props }) => (
                      <Button
                        {...props}
                        appearance='subtle'
                        iconBefore={<MoreIcon label='more' />}
                        ref={triggerRef}
                      />
                    )}
                  >
                    <DropdownItemGroup>
                      <DropdownItem onClick={() => openRoleDialog(item)}>
                        Edit
                      </DropdownItem>
                      <DropdownItem isDisabled>
                        Delete
                      </DropdownItem>
                    </DropdownItemGroup>
                  </DropdownMenu>
                </div>
              )
            }
          ]
        }))}
        emptyView={
          <EmptyState
            header='No data available here'
            description='Make sure you have the right permissions to see this content'
            imageUrl='/static/illustrations/illustration_empty_content.svg'
          />
        }
      />
      <EditRoleDialog role={selectedRole} />
    </Page>
  )
}
