import { atom } from 'recoil'
import React, { ReactNode } from 'react'

export const rightPanelOpenState = atom<boolean>({
  key: 'right-panel-open-state',
  default: false
})

export const rightPanelRenderState = atom<(() => React.ReactNode) | undefined>({
  key: 'right-panel-render-state',
  default: undefined
})
