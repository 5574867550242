import React, { useState, useEffect, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import LoadingScreen from '../../components/loading-screen'
import { useRecoilState } from 'recoil'
import { authUserState } from '../../recoil/user'
import apiFetch from '../../utils/apiFetch'

// ----------------------------------------------------------------------

interface AuthGuardProps {
  children: ReactNode
}

export default function AuthGuard ({ children }: AuthGuardProps): JSX.Element {
  const [user, setUser] = useRecoilState(authUserState)
  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

  useEffect(() => {
    apiFetch('users/current').then(user => {
      setUser(user)
    }).catch(e => {
      console.log(e)
    })
  }, [])

  if (!user) {
    return <LoadingScreen />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
}
