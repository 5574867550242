import { atom } from 'recoil'
import { CurrentUser } from 'common/types/current-user'

export interface AuthUser extends CurrentUser{

}

export const authUserState = atom<AuthUser>({
  key: 'auth-user',
  default: undefined
})
