import React, { ReactElement } from 'react'
import { LoadingButton } from '@atlaskit/button'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition
} from '@atlaskit/modal-dialog'

interface ModalProps {
  isOpen: boolean
  appearance?: 'warning' | 'danger'
  header?: string
  primaryButtonText?: string
  secondaryButtonText?: string
  onClose: (payload?: any) => void
  onPrimaryClick?: (e: any) => void
  onSecondaryClick?: (e: any) => void
  children?: ReactElement | ReactElement[]
  isPrimaryButtonLoading?: boolean
  isPrimaryButtonDisabled?: boolean
  isSecondaryButtonDisabled?: boolean
  testId?: string
}
export const ModalDialog = (props: ModalProps) => {
  return (
    <ModalTransition>
      {props.isOpen && (
        <Modal onClose={() => props.onClose()} testId={props.testId}>
          <ModalHeader>
            <ModalTitle appearance={props.appearance}>{props.header}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {props.children}
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              appearance='subtle'
              isDisabled={props.isSecondaryButtonDisabled}
              onClick={(e) => props.onSecondaryClick?.(e)}
            >
              {props.secondaryButtonText ?? 'Cancel'}
            </LoadingButton>
            <LoadingButton
              appearance={props.appearance ?? 'primary'}
              isLoading={props.isPrimaryButtonLoading}
              isDisabled={props.isPrimaryButtonDisabled}
              onClick={(e) => props.onPrimaryClick?.(e)} autoFocus
            >
              {props.primaryButtonText ?? 'Confirm'}
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}
