import React from 'react'
import { PageConfig } from '../../types/app-config/page-config'
import useCondition from '../../hooks/use-condition/useCondition'

interface PageGuardProps {
  config: PageConfig
  children: React.ReactNode
}

const PageGuard = ({ config, children }: PageGuardProps): JSX.Element | null => {
  const condition = useCondition()
  if (config.isHidden) {
    return null
  }
  if (config.conditions && !condition.evaluate(config.conditions)) {
    return null
  }
  return <>{children}</>
}

export default PageGuard
