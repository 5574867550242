import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import {
  CustomPropertyPrefix,
  PropertyFieldConfiguration,
  PropertyFieldType
} from 'common/types/crm/property-schema'
import TextField from '@atlaskit/textfield'
import TextArea from '@atlaskit/textarea'
import Select, { CreatableSelect } from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'
import { PropertyField } from './property-field/property-field'
import { ErrorMessage, HelperMessage } from '@atlaskit/form'
import {
  EntityGroup,
  EntityPropertySchema
} from 'common/types/odino/entity-schema'

interface PropertyEditorProps {
  value?: EntityPropertySchema
  onChange?: (property: EntityPropertySchema, isInvalid: boolean) => void
  isEdit?: boolean
  properties: { [key: string]: EntityPropertySchema }
  groups: { [key: string]: EntityGroup }
}

export const PropertyEditor = ({ value, onChange, isEdit, properties, groups }: PropertyEditorProps): ReactElement => {
  const [property, setProperty] = useState<EntityPropertySchema>(value ?? {})
  const [idError, setIdError] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (idExist(property)) {
      // Id already exists
      setIdError('This id is associated to another property')
    } else {
      setIdError(undefined)
    }
    if (onChange) {
      onChange(property, isInvalid())
    }
  }, [property])

  const isInvalid = (): boolean => {
    return !property?.id ||
      !property?.id?.startsWith(CustomPropertyPrefix) ||
      !property.name ||
      !property.type ||
      !property.groupId ||
      idExist(property)
  }

  const idExist = (property: any): boolean => {
    return property.id && properties[property.id] && !isEdit
  }

  const updateProperty = (value: any): void => {
    const newProp = { ...property, ...value }
    setProperty(newProp)
  }

  const updateOptions = (values: string[]) => {
    if (property.veiveFieldType === PropertyFieldType.selectsingle) {
      updateProperty({ enum: values })
    }
    if (property.veiveFieldType === PropertyFieldType.selectmulti) {
      const newItems = { ...property.items }
      newItems.enum = values
      updateProperty({ items: newItems })
    }
  }

  const getFieldTypeValue = () => {
    if (!property || !property.veiveFieldType) {
      return undefined
    }
    const ftype = PropertyFieldConfiguration[property.veiveFieldType]
    return { key: ftype.id, label: ftype.label, value: ftype.id, field: ftype }
  }

  return (
    <div style={{ flex: 1, marginTop: 16, overflowY: 'auto' }}>
      <label>Name *</label>
      <TextField
        value={property.name} onChange={(e: ChangeEvent<HTMLInputElement>) => {
          updateProperty({ name: e.target.value, id: !isEdit ? `${CustomPropertyPrefix}${e.target.value.toLowerCase().trim().replaceAll(/\s/g, '_')}` : property.id })
        }}
      />
      <label style={{ marginTop: 16 }}>Id*</label>
      <TextField value={property.id} isDisabled={isEdit} onChange={(e: ChangeEvent<HTMLInputElement>) => updateProperty({ id: e.target.value })} />
      <HelperMessage>Must be in the format cp_*</HelperMessage>
      {
        idError &&
          <ErrorMessage>{idError}</ErrorMessage>
      }
      <label style={{ marginTop: 16 }}>Property Group*</label>
      <Select
        menuPosition='fixed'
        placeholder='Select a group'
        defaultValue={property.groupId ? { key: property.groupId, label: groups[property.groupId].name, value: property.groupId } : undefined}
        options={Object.values(groups).map(g => ({ key: g.id, label: g.name, value: g.id }))}
        onChange={item => updateProperty({ groupId: item?.value })}
      />
      <label style={{ marginTop: 16 }}>Description</label>
      <TextArea value={property.description} onChange={e => updateProperty({ description: e.target.value })} />
      <label style={{ marginTop: 16 }}>Type*</label>
      <Select
        value={getFieldTypeValue()}
        menuPosition='fixed'
        onChange={q => updateProperty({ veiveFieldType: q?.field.veiveFieldType, type: q?.field.type, format: q?.field.format, enum: q?.field.enum, items: q?.field.items })}
        options={Object.values(PropertyFieldConfiguration).map(q => ({ key: q.id, label: q.label, value: q.id, field: q }))}
      />
      {
        (property.veiveFieldType === PropertyFieldType.selectsingle || property.veiveFieldType === PropertyFieldType.selectmulti) &&
          <div>
            <label style={{ marginTop: 16 }}>Options</label>
            <CreatableSelect
              menuPosition='fixed'
              isMulti
              openMenuOnClick={false}
              placeholder='Add option'
              defaultValue={property.enum?.map(q => ({ value: q, label: q })) ?? property.items?.enum?.map((q: any) => ({ value: q, label: q }))}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              onChange={(items: any) => updateOptions(items?.map((q: {value: string}) => q.value))}
            />
          </div>
      }
      <label style={{ marginTop: 16 }}>Required</label>
      <p style={{ margin: 0 }}>
        <Toggle
          defaultChecked={property.required}
          onChange={e => updateProperty({ required: e.target.checked })}
        />
      </p>
      <label style={{ marginTop: 16 }}>Show on forms</label>
      <p style={{ margin: 0 }}>
        <Toggle
          defaultChecked={property.veiveShowOnForms}
          onChange={e => updateProperty({ veiveShowOnForms: e.target.checked })}
        />
      </p>
      {
        property.veiveFieldType &&
          <div>
            <label style={{ marginTop: 16 }}>Preview</label>
            <div style={{ border: '1px solid rgb(223, 225, 230)', borderRadius: 3, padding: '32px 16px', marginTop: 4 }}>
              <PropertyField
                property={property}
                isCompact
                placeholder='Enter value here..'
              />
            </div>
          </div>
      }

    </div>
  )
}
