import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Page } from './page'
import Button from '@atlaskit/button'

// ----------------------------------------------------------------------

interface PageErrorProps {
  code: 404 | 403
  testId?: string
}

export const PageError = (props: PageErrorProps): JSX.Element => {
  const navigate = useNavigate()
  const resourcesMap: {[key: number]: { [key: string]: string }} = {
    403: {
      pageTitle: 'Access Denied',
      title: 'Restricted Section',
      description: "Sorry, seems you don't have the permission to access this content.",
      illustration: '/static/illustrations/illustration_403.svg'
    },
    404: {
      pageTitle: 'Page Not Found',
      title: 'Sorry, page not found!',
      description: 'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
      illustration: '/static/illustrations/illustration_404.svg'
    }
  }

  return (
    <Page
      metaTitle={resourcesMap[props.code].title}
      isCentered
    >
      <h1>{resourcesMap[props.code].title}</h1>
      <p>{resourcesMap[props.code].description}</p>
      <img alt='Error' style={{ height: 230, marginTop: 30 }} src={resourcesMap[props.code].illustration} />
      <div style={{ marginTop: 60 }}>
        <Button
          appearance='primary'
          onClick={() => navigate('/')}
          testId={`${props.testId}-gohomebtn`}
        >
          Go to Home
        </Button>
      </div>
    </Page>
  )
}
