import React, { ReactElement, useEffect, useState } from 'react'
import { Page } from '../../../common/components/pages/page'
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button'
import { Role } from 'common/types/role'
import { VeiveUser } from 'common/types/current-user'
import apiFetch from '../../../common/utils/apiFetch'
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled'
import TrashIcon from '@atlaskit/icon/glyph/trash'
import EmptyState from '@atlaskit/empty-state'
import DynamicTable from '@atlaskit/dynamic-table'
import { FullAvatar } from '../../../common/components/avatar/full-avatar'
import { ModalDialog } from '../../../common/components/modal/modal-dialog'
import Select from '@atlaskit/select'
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup
} from '@atlaskit/dropdown-menu'
import MoreIcon from '@atlaskit/icon/glyph/more'

export const AdminPageUsers = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [roles, setRoles] = useState<Role[]>([])
  const [users, setUsers] = useState<VeiveUser[]>([])
  const [selectedUser, setSelectedUser] = useState<VeiveUser | undefined>()

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!dialogOpen) {
      fetchData()
    }
  }, [dialogOpen])

  const fetchData = async () => {
    const [roleResponse, usersResponse] = await Promise.all([apiFetch('roles'), apiFetch('users')])
    setRoles(roleResponse.roles)
    setUsers(usersResponse.users)
  }

  const openUserDialog = (user?: VeiveUser): void => {
    setSelectedUser(user)
    setDialogOpen(true)
  }

  const EditUserDialog = (props: {user?: VeiveUser}): ReactElement => {
    const [selectedRoles, setSelectedRoles] = useState<string[]>(props.user?.roles ?? [])
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const handleSave = async () => {
      setIsSaving(true)
      try {
        const resp = await apiFetch(`users/${props.user?.id}/roles`, {
          method: 'PUT',
          body: JSON.stringify({ roles: selectedRoles })
        })
        setDialogOpen(false)
      } catch (e) {
        console.log(e)
      }
      setIsSaving(false)
    }

    const handleRoleChanges = (newSelection: any[]) => {
      const newRoles = newSelection.map(q => q.value)
      setSelectedRoles(newRoles)
    }

    const roleIdToOption = (roleId: string) => {
      return {
        key: roleId,
        label: roles.find(q => q.id === roleId)?.name,
        value: roleId
      }
    }

    const roleToOption = (role: Role) => {
      return {
        key: role.id,
        label: role.name,
        value: role.id
      }
    }

    return (
      <ModalDialog
        isOpen={dialogOpen}
        header='Edit user'
        onClose={() => setDialogOpen(false)}
        onSecondaryClick={() => setDialogOpen(false)}
        onPrimaryClick={async () => await handleSave()}
        isPrimaryButtonLoading={isSaving}
      >
        <FullAvatar avatarUrl={props.user?.avatarUrl} label={props.user?.displayName} />
        <label style={{ marginTop: 20 }}>Email</label>
        <p style={{ margin: 0 }}>{props.user?.email}</p>
        <label style={{ marginTop: 20 }}>Roles</label>
        <div style={{ marginBottom: 20 }}>
          <Select
            isMulti
            defaultValue={props.user?.roles?.map(q => roleIdToOption(q))}
            options={roles.map(q => roleToOption(q))}
            menuPosition='fixed'
            onChange={(items) => handleRoleChanges(items as any[])}
          />
        </div>

      </ModalDialog>
    )
  }

  return (
    <Page
      title='Users'
      pageBreadcrumbs={[{
        path: '/',
        text: 'Home'
      },
      {
        path: '/admin',
        text: 'Admin'
      },
      {
        path: '/admin/users',
        text: 'Users'
      }]}
      actions={
        <Button isDisabled appearance='primary'>
          New User
        </Button>
      }
    >
      <DynamicTable
        isLoading={users.length === 0}
        isFixedSize
        head={{
          cells: [
            {
              key: 'user',
              content: 'User',
              isSortable: false
            },
            {
              key: 'email',
              content: 'Email'
            },
            {
              key: 'roles',
              content: 'Roles'
            },
            {
              key: 'actions',
              content: ''
            }
          ]
        }}
        rows={users.map((item, index) => ({
          key: `row-${index}`,
          cells: [
            {
              key: `row-${index}-name`,
              content: <FullAvatar avatarUrl={item?.avatarUrl} label={item?.displayName} />
            },
            {
              key: `row-${index}-email`,
              content: item.email
            },
            {
              key: `row-${index}-roles`,
              content: item.roles?.map(q => roles.find(s => s.id === q)?.name ?? '').join(', '),
              shouldTruncate: true
            },
            {
              key: `row-${index}-actions`,
              content:
                (
                  <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <DropdownMenu
                      placement='bottom-end'
                      trigger={({ triggerRef, ...props }) => (
                        <Button
                          {...props}
                          appearance='subtle'
                          iconBefore={<MoreIcon label='more' />}
                          ref={triggerRef}
                        />
                      )}
                    >
                      <DropdownItemGroup>
                        <DropdownItem onClick={() => openUserDialog(item)}>
                          Edit
                        </DropdownItem>
                        <DropdownItem isDisabled>
                          Delete
                        </DropdownItem>
                      </DropdownItemGroup>
                    </DropdownMenu>
                  </div>
                )
            }
          ]
        }))}
        emptyView={
          <EmptyState
            header='No data available here'
            description='Make sure you have the right permissions to see this content'
            imageUrl='/static/illustrations/illustration_empty_content.svg'
          />
        }
      />
      <EditUserDialog user={selectedUser} />
    </Page>
  )
}
