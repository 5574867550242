export interface Property {
  id?: string
  label?: string
  description?: string
  type?: PropertyType
  format?: PropertyFormat
  enum?: string[]
  items?: any
  fieldType?: PropertyFieldType
  renderType?: PropertyRenderType
  minLength?: number
  maxLength?: number
  required?: boolean
  readOnly?: boolean
  showOnForms?: boolean
}

export enum PropertyType{
  string = 'string',
  number = 'number',
  integer = 'integer',
  object = 'object',
  array = 'array',
  boolean = 'boolean',
  null = 'null'
}

export enum PropertyFormat {
  'date-time' = 'date-time',
  time = 'time',
  date = 'date',
  email = 'email',
  hostname = 'hostname',
  ipv4 = 'ipv4',
  ipv6 = 'ipv6',
  uuid = 'uuid',
  uri = 'uri',
  regex = 'regex'
}

export enum PropertyFieldType {
  text = 'text',
  textarea = 'textarea',
  textnumber = 'textnumber',
  selectsingle = 'selectsingle',
  selectmulti = 'selectmulti',
  datetime = 'datetime',
  date = 'date',
  time = 'time',
  toggle = 'toggle',
  relation = 'relation'
}

export enum PropertyRenderType {
  text = 'text',
  date = 'date',
  time = 'time',
  datetime = 'datetime',
  link = 'link',
  email = 'email',
  phone = 'phone',
  entity = 'entity',
  image = 'image',
  avatar = 'avatar',
  relation = 'relation'
}

export const PropertyFieldConfiguration: {[key: string]: any} = {
  text: { id: 'text', label: 'Text single line', type: 'string', veiveFieldType: 'text' },
  textarea: { id: 'textarea', label: 'Text multi line', type: 'string', veiveFieldType: 'textarea' },
  textnumber: { id: 'textnumber', label: 'Number', type: 'number', veiveFieldType: 'textnumber' },
  selectsingle: { id: 'selectsingle', label: 'Single select', type: 'string', enum: [], veiveFieldType: 'selectsingle' },
  selectmulti: { id: 'selectmulti', label: 'Multi select', type: 'array', items: { type: 'string', enum: [], maxLength: 255 }, veiveFieldType: 'selectmulti' },
  datetime: { id: 'datetime', label: 'Date time', type: 'string', format: 'date-time', veiveFieldType: 'datetime' },
  date: { id: 'date', label: 'Date', type: 'string', format: 'date', veiveFieldType: 'date' },
  time: { id: 'time', label: 'Time', type: 'string', format: 'time', veiveFieldType: 'time' },
  toggle: { id: 'toggle', label: 'Toggle', type: 'boolean', veiveFieldType: 'toggle' }
  /* bool: { label: 'Switch', type: 'boolean' },
  select: { label: 'Single select' } */
}
export const CustomPropertyPrefix = 'cp_'
export const InternationalPhoneRegex = '(\\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{4,20}$'
export const DomainRegex = '^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+$'

export interface FormattedProperty {
  type: PropertyType
  renderType?: PropertyRenderType
  veiveRenderType?: PropertyRenderType
  label: string
  [key: string]: any
}
