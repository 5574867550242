import React from 'react'
import { SideNavigation, NavigationHeader, NavigationContent, Section, ButtonItem, GoBackItem } from '@atlaskit/side-navigation'
import { AppConfigDescriptor } from '../../common/types/app-config'
import { LeftSidebar } from '@atlaskit/page-layout'
import { useLocation, useNavigate } from 'react-router-dom'
import PageGuard from '../../common/components/guards/page-guard'

interface AdminSideNavigationProps {
  apps: AppConfigDescriptor[]
  testId?: string
}
export const AdminSideNavigation = ({ apps, testId }: AdminSideNavigationProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const buildNavigationMenu = () => {
    const sections = []

    for (const app of apps) {
      const adminLinks = []
      if (app.modules?.adminPages) {
        for (const pageConfig of app.modules?.adminPages) {
          const link = (
            <PageGuard config={pageConfig}>
              <ButtonItem
                iconBefore={pageConfig.icon}
                key={`nav-${app.name}-${pageConfig.key}`}
                onClick={() => navigate('/admin' + app.basePath + pageConfig.path)}
                isSelected={location.pathname === '/admin' + app.basePath + pageConfig.path}
              >
                {pageConfig.title}
              </ButtonItem>
            </PageGuard>
          )
          if (link) {
            adminLinks.push(link)
          }
        }
      }
      if (adminLinks.length > 0) {
        sections.push(
          <Section title={app.name}>
            {adminLinks}
          </Section>)
      }
    }
    return sections
  }

  return (
    <LeftSidebar
      isFixed
      width={280}
      testId={testId ? `${testId}-sidebar` : undefined}
    >
      <SideNavigation label='Veive CRM'>
        <NavigationHeader>
          <GoBackItem testId={testId ? `${testId}-goback` : undefined} onClick={() => navigate('/')}>Back to Home</GoBackItem>
        </NavigationHeader>
        <NavigationContent showTopScrollIndicator testId={testId ? `${testId}-navcontent` : undefined}>
          {buildNavigationMenu()}
        </NavigationContent>
      </SideNavigation>
    </LeftSidebar>
  )
}
